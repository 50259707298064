import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ForgotPasswordInput } from '../../../interfaces/Auth';
import { useForm } from '../../../hooks/useForm';
import { emailValidator } from '../../../utils/validators/emailValidator';
import authService from '../../../services/AuthService';
import useApi from '../../../hooks/useApi';

const request_reset = (data: ForgotPasswordInput) =>
	authService.get_password_reset_link(data);

export const ForgotPassword: React.FC = () => {
	const forgotPasswordForm = useForm<ForgotPasswordInput>(
		{
			email: '',
		},
		{
			email: emailValidator,
		}
	);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		forgotPasswordForm.onChange(
			e.target.name as keyof ForgotPasswordInput,
			e.target.value
		);
	};

	const forgotPasswordApiRequest = useApi<
		{ status: boolean; message: string },
		ForgotPasswordInput
	>(request_reset);

	useEffect(() => {
		if (forgotPasswordApiRequest.error)
			toast(forgotPasswordApiRequest.error, {
				type: 'error',
				theme: 'colored',
			});
	}, [forgotPasswordApiRequest.error]);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (forgotPasswordApiRequest.loading) {
			return;
		}

		forgotPasswordForm.resetFormErrors();
		forgotPasswordApiRequest.reset();

		if (forgotPasswordForm.validate()) {
			const result = await forgotPasswordApiRequest.request(
				forgotPasswordForm.form
			);

			if (result && result.status) {
				toast(result.message, {
					theme: 'colored',
					type: 'success',
				});

				forgotPasswordForm.reset();
			}
		}
	};

	return (
		<div className='bv-forgot-password-page'>
			<div className='container my-auto py-5'>
				<div className='row g-0'>
					<div className='col-11 col-md-8 col-lg-7 col-xl-6 mx-auto'>
						<h3 className='text-white mb-4'>Forgot Password</h3>

						<div className='alert alert-info text-center'>
							<b>Forgot your Password?</b> Do not worry! Kindly enter the E-mail
							address you used in setting up your account in the filed below,
							and we will send you, a password reset instruction!
						</div>

						<div className='d-flex align-items-center my-4'>
							<hr className='flex-grow-1 bg-dark-4' />
						</div>

						<form onSubmit={handleSubmit} className='form-dark' autoComplete=''>
							<div className='mb-3'>
								<label className='form-label text-light'>Email</label>
								<input
									type='email'
									className='form-control'
									name='email'
									value={forgotPasswordForm.form.email}
									onChange={handleChange}
									placeholder='Enter Your Email'
								/>
							</div>

							<button
								className='btn btn-primary my-2'
								name='submit'
								type='submit'
								disabled={forgotPasswordApiRequest.loading}
							>
								Forgot Password
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
