import axios from 'axios';
import { API_URL } from '../config/env';
import { BvTokens } from '../interfaces/Auth';
import { CreditCardDepoistInput, DepositInput } from '../interfaces/Deposit';

class DepositService {
	get_address_for_deposit = (tokens: BvTokens, currency: 'btc' | 'eth') => {
		return axios.get(`${API_URL}/deposit/wallet-address?currency=${currency}`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	submit_deposit = (tokens: BvTokens, data: DepositInput) => {
		const payload = new FormData();
		payload.append('receipt', data.receipt);
		payload.append('usd_amount', data.usd_amount.toString());

		return axios.post(`${API_URL}/deposit`, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	submit_deposit_card = (tokens: BvTokens, data: CreditCardDepoistInput) => {
		return axios.post(`${API_URL}/deposit/card`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	get_deposit_history = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/deposit`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const depositService = new DepositService();
export default depositService;
