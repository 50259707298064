import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const LivePayouts: React.FC = () => {

    return (
        <div className="bv-live-payouts-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>Live Payouts</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>Live Payouts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-details-area section-padding-100-50 bg-gray-cu">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details-content mb-50">
                                <h2 className="uk-text-center">Discover Our Live Payouts</h2>
                                <p>
                                    <span style={{
                                        color: '#ff0000',
                                        fontSize: '11pt'
                                    }}>
                                        <strong>Here you can explore our live proof of payment with the amount, BTC address and transactions Id. Click On The Wallet Addresses Displaying On Your Screen To Verify Recent Payment Transactions. 
                                            <span style={{
                                                color: '#fff'
                                            }}>Start trading and get your Bitcoin address listed on our live payment statistics.
                                            </span>
                                        </strong>
                                    </span>
                                </p>               
                                <iframe src="https://www.btcwidget.info/widget/liveTx/%23ffffff/%23f5df1d/%23eb174a/%2301000a/%23fffffc/800/550/10" width="800" height="550" scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}