import axios from 'axios';
import { API_URL } from '../config/env';
import {
	BvTokens,
	CompleteProfileInput,
	ForgotPasswordInput,
	LoginInput,
	RegisterInput,
	ResetPasswordInput,
	UserIdentificationInput,
} from '../interfaces/Auth';

class AuthService {
	register = (data: RegisterInput) => {
		if (data.password !== data.confirmPassword) {
			throw new Error('Password and Confirm Password does not match');
		}
		return axios.post(`${API_URL}/auth/register`, data);
	};

	login = (data: LoginInput) => {
		return axios.post(`${API_URL}/auth/login`, data);
	};

	complete_profile = (data: CompleteProfileInput) => {
		return axios.post(`${API_URL}/auth/complete-profile`, data);
	};

	get_password_reset_link = (data: ForgotPasswordInput) => {
		return axios.get(`${API_URL}/auth/reset-password?email=` + data.email);
	};

	confirm_password_reset = (data: ResetPasswordInput) => {
		return axios.post(`${API_URL}/auth/reset-password`, data);
	};

	get_password_reset_link_details = (hash: string) => {
		return axios.get(`${API_URL}/auth/reset-password/details?hash=` + hash);
	};

	save_identification = (data: UserIdentificationInput) => {
		if (!data.id_file) {
			throw new Error('Please select file!');
		}
		if (!data.session) {
			throw new Error('Session is required');
		}
		const payload = new FormData();
		payload.append('id_file', data.id_file);
		payload.append('method', data.method);
		payload.append('session', data.session);

		return axios.post(`${API_URL}/users/user-identification`, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	save_identification_verif = (
		tokens: BvTokens,
		data: UserIdentificationInput
	) => {
		if (!data.id_file) {
			throw new Error('Please select file!');
		}

		const payload = new FormData();
		payload.append('id_file', data.id_file);
		payload.append('method', data.method);

		return axios.post(`${API_URL}/profile/user-verification`, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	confirm_email = (hash: string) => {
		return axios.post(`${API_URL}/auth/verify-email`, { hash });
	};

	get_email_verification_link = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/auth/verify-email`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const authService = new AuthService();
export default authService;
