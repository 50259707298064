interface InputFieldProps<T> {
	type?: 'text' | 'password' | 'number';
	label: string;
	value: string;
	placeholder?: string;
	onChange: (name: keyof T, value: string | number) => void;
	name: keyof T;
	error: string | null;
	min?: number;
	maxLength?: number;
}

const InputField = <T,>(props: InputFieldProps<T>) => {
	const {
		type = 'text',
		value,
		onChange,
		name,
		error,
		placeholder,
		label,
		maxLength,
		min,
	} = props;

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		if (type === 'number' && maxLength && value.length === maxLength) {
			return;
		}

		onChange(e.target.name as keyof T, e.target.value);
	};

	return (
		<>
			<label htmlFor={name.toString() + '-id'} className='form-label'>
				{label}
			</label>

			<input
				type={type}
				id={name.toString() + '-id'}
				className='form-control'
				name={name.toString()}
				value={value}
				onChange={handleChange}
				placeholder={placeholder}
				min={min}
				maxLength={maxLength}
			/>

			{error && (
				<div className='input-error mt-1'>
					{(
						name.toString().substring(0, 1).toUpperCase() +
						name.toString().substring(1)
					).replace('_', ' ') +
						' ' +
						error}
				</div>
			)}
		</>
	);
};

export default InputField;
