import React from 'react';
import KrManagerNav from '../../../components/KrManagerNav';
import { useForm } from '../../../hooks/useForm';
import { ChangePasswordInput } from '../../../interfaces/User';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { BvTokens } from '../../../interfaces/Auth';
import userService from '../../../services/UserService';
import useApi from '../../../hooks/useApi';
import { useScreenActions } from '../../../hooks/useReduxActions';

const change_password = (data: {
	tokens: BvTokens;
	data: ChangePasswordInput;
}) => userService.change_password(data.tokens, data.data);

export const ChangePassword: React.FC = () => {
	const { tokens } = useAppSelector((state) => state.authReducer);

	const changePasswordForm = useForm<ChangePasswordInput>(
		{
			current: '',
			password: '',
			confirmPassword: '',
		},
		{
			current: emptyValidator,
			password: emptyValidator,
			confirmPassword: emptyValidator,
		}
	);

	const changePasswordApiRequest = useApi<
		{ status: boolean },
		{ tokens: BvTokens; data: ChangePasswordInput }
	>(change_password);

	const handleChange: React.ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
	> = (e) => {
		changePasswordForm.onChange(
			e.target.name as keyof ChangePasswordInput,
			e.target.value
		);
	};

	const { toggleSuccessAlert } = useScreenActions();

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		changePasswordForm.resetFormErrors();
		changePasswordApiRequest.reset();

		const valid = changePasswordForm.validate();

		if (valid && tokens) {
			const result = await changePasswordApiRequest.request({
				data: { ...changePasswordForm.form },
				tokens,
			});

			if (result && result.status) {
				// Password updated successfully
				toggleSuccessAlert('Password Changed Successfully.');
				changePasswordForm.reset();
			}
		}
	};

	return (
		<div className='bv-change-password-page'>
			<KrManagerNav />

			<div className='kr-manager-filter'>
				<button
					type='button'
					className='btn btn-autowidth btn-red btn-large'
					name='button'
				>
					Change Password
				</button>
			</div>

			<div className='kr-admin-table'>
				<form onSubmit={handleSubmit}>
					<div className='kr-admin-line kr-admin-line-cls'>
						<div className='kr-admin-field'>
							<div>
								<label>Current Password</label>
							</div>
							<div>
								<input
									type='password'
									name='current'
									value={changePasswordForm.form.current}
									onChange={handleChange}
									placeholder='Current Password'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>New Password </label>
							</div>
							<div>
								<input
									className='form-control'
									type='password'
									name='password'
									placeholder='New Password'
									value={changePasswordForm.form.password}
									onChange={handleChange}
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Confirm Password </label>
							</div>
							<div>
								<input
									className='form-control'
									type='password'
									name='confirmPassword'
									value={changePasswordForm.form.confirmPassword}
									onChange={handleChange}
									placeholder='Confirm Password'
								/>
							</div>
						</div>

						{changePasswordApiRequest.error && (
							<div className='mt-3 api-error alert alert-danger text-center'>
								{changePasswordApiRequest.error}
							</div>
						)}

						<div className='kr-admin-action'>
							<input
								type='submit'
								className='btn btn-orange btn-autowidth'
								value='Change Password'
								disabled={changePasswordApiRequest.loading}
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
