export const RE_DIGIT = new RegExp(/^\d+$/);

export const AllRouteConstants = {
	auth: {
		index: '/auth',
		login: '/auth/signin',
		signup: '/auth/signup',
		forgot_password: '/auth/forgot-password',
		reset_password: '/auth/reset-password',
		complete_profile: '/auth/complete-profile',
		user_identification: '/auth/user-identification',
	},
	main: {
		index: '/',
		about_us: '/about-us',
		trading_plans: '/pricing',
		contact_us: '/contact-us',
		bitcoin_trading: '/bitcoin-trading',
		bitcoin_cash_trading: '/bitcoin-cash',
		litecoin_trading: '/litecoin-trading',
		etherum_trading: '/etherum-trading',
		ripple_trading: '/ripple-trading',
		financial_instruments: '/financial-instruments',
		risk_disclosure: '/risk-disclosure',
		live_payouts: '/live-payouts',
		terms_of_use: '/legal',
		privacy_policy: '/privacy',
		aml_policy: '/aml-policy',
		payment_policy: '/payment-policy',
		email_verify: '/verify-email',
	},
	user: {
		index: '/user',
		home: '/user/home',
		trade_history: '/user/trade-history',
		deposit_fund: '/user/deposit',
		deposit_history: '/user/deposit-log',
		withdraw_fund: '/user/withdraw-fund',
		withdraw_history: '/user/withdraw-log',
		profit: '/user/earnings',
		plan: '/user/plan',
		edit_profile: '/user/edit-profile',
		change_password: '/user/change-password',
		verify: '/user/verify',
	},
};
