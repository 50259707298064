import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Route Constants
import { AllRouteConstants } from './routes';
// scroll
import Scroll from '../components/scrollToTop/ScrollToTop';
// Routes

// Layouts
import NotFound from '../pages/NotFound/NotFound';
import PublicLayout from '../layouts/PublicLayout';
import PublicRoutes from '../features/public/routes/Routes';
import AuthLayout from '../layouts/AuthLayout';
import AuthRoutes from '../features/auth/routes/Routes';
import UserLayout from '../layouts/UserLayout';
import TradingRoutes from '../features/trading/routes/Routes';
import DepositRoutes from '../features/deposit/routes/Routes';
import WithdrawRoutes from '../features/withdraw/routes/Routes';
import ProfitRoutes from '../features/profit/routes/Routes';
import PlanRoutes from '../features/plan/routes/Routes';
import ProfileRoutes from '../features/profile/routes/Routes';
import { VerifyEmail } from '../features/public/pages';

function Router() {
	return (
		<BrowserRouter>
			<Scroll />
			<Routes>
				{/** PUBLIC ROUTES */}
				<Route path={AllRouteConstants.main.index} element={<PublicLayout />}>
					{PublicRoutes()}
				</Route>

				<Route
					path={AllRouteConstants.main.email_verify}
					element={<VerifyEmail />}
				/>

				{/** AUTH ROUTES */}
				<Route path={AllRouteConstants.auth.index} element={<AuthLayout />}>
					{AuthRoutes()}
				</Route>

				{/** USER ROUTES */}
				<Route path={AllRouteConstants.user.index} element={<UserLayout />}>
					{TradingRoutes()}

					{DepositRoutes()}

					{WithdrawRoutes()}

					{ProfitRoutes()}

					{PlanRoutes()}

					{ProfileRoutes()}
				</Route>

				<Route path={'*'} element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
