import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../router/routes';
import { useAppSelector } from '../hooks/useAppSelector';

export interface RequireAuthProps {
	children: ReactElement;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
	const { user_data, tokens } = useAppSelector((state) => state.authReducer);

	const navigate = useNavigate();

	useEffect(() => {
		if (!user_data || !tokens) {
			navigate(AllRouteConstants.auth.login, {
				replace: true,
			});
		}
	}, [user_data, tokens]);

	return children;
};
