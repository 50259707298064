import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { ScreenActionTypes } from './action-types';
import { ProfileVerifyData } from '../../interfaces/Profile';

export const setProfileVerifyData =
	(data?: ProfileVerifyData) => (dispatch: Dispatch<ScreenActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_PROFILE_VERIFY_DATA,
			payload: data || null,
		});
	};
