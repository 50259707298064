import React from 'react';

interface AuthInputFieldProps {
	type: 'text' | 'password';
	value: string;
	placeholder: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	name: string;
	error: string | null;
}

const AuthInputField: React.FC<AuthInputFieldProps> = (props) => {
	const { type, value, onChange, name, error, placeholder } = props;

	return (
		<>
			<input
				type={type}
				className='form-control'
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
			/>

			{error && (
				<div className='input-error mt-1'>
					{name.substring(0, 1).toUpperCase() + name.substring(1) + ' ' + error}
				</div>
			)}
		</>
	);
};

export default AuthInputField;
