import React, { useEffect, useRef } from 'react';
import KrManagerNav from '../../../components/KrManagerNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { User, UserProfileInput } from '../../../interfaces/User';
import { useForm } from '../../../hooks/useForm';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import { nameValidator } from '../../../utils/validators/nameVaildator';
import userService from '../../../services/UserService';
import useApi from '../../../hooks/useApi';
import { BvTokens } from '../../../interfaces/Auth';
import { useAuthActions } from '../../../hooks/useReduxActions';
import defaultImg from '../../../assets/images/default.jpeg';
import { toast } from 'react-toastify';

const update_profile = (data: { tokens: BvTokens; data: UserProfileInput }) =>
	userService.update_profile(data.tokens, data.data);

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const EditProfile: React.FC = () => {
	const avatarSelectRef = useRef<HTMLInputElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const { user_data, tokens } = useAppSelector((state) => state.authReducer);
	const { updateUserData } = useAuthActions();

	const profileForm = useForm<UserProfileInput>(
		{
			address: '',
			city: '',
			country: '',
			firstname: '',
			lastname: '',
			gender: '',
			phone: '',
			state: '',
		},
		{
			address: emptyValidator,
			city: emptyValidator,
			country: emptyValidator,
			firstname: nameValidator,
			lastname: nameValidator,
			gender: emptyValidator,
			state: emptyValidator,
		}
	);

	const profileApiRequest = useApi<
		{ status: boolean; data: User },
		{ tokens: BvTokens; data: UserProfileInput }
	>(update_profile);

	useEffect(() => {
		if (user_data) {
			profileForm.onChange('address', user_data.address);
			profileForm.onChange('firstname', user_data.firstname);
			profileForm.onChange('lastname', user_data.lastname);
			profileForm.onChange('city', user_data.city);
			profileForm.onChange('state', user_data.state);
			profileForm.onChange('phone', user_data.phone);
			profileForm.onChange('country', user_data.country);
			profileForm.onChange('gender', user_data.gender);
		}
	}, [user_data]);

	const handleChange: React.ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
	> = (e) => {
		profileForm.onChange(
			e.target.name as keyof UserProfileInput,
			e.target.value
		);
	};

	const handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		if (e.target.files) {
			const file = e.target.files[0];

			if (file.size > MAX_FILE_SIZE) {
				console.log('Too Large');
				return;
			}

			profileForm.onChange('avatar', e.target.files[0]);
		}
	};

	function myFunction() {
		// Get the text field
		if (!inputRef.current) {
			return;
		}

		// Select the text field
		inputRef.current.select();
		inputRef.current.setSelectionRange(0, 99999); // For mobile devices

		// Copy the text inside the text field
		navigator.clipboard.writeText(inputRef.current.value);

		// Alert the copied text
		alert('Referral code: ' + inputRef.current.value);
	}

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		profileForm.resetFormErrors();
		profileApiRequest.reset();

		const valid = profileForm.validate();

		if (valid && tokens) {
			const result = await profileApiRequest.request({
				data: { ...profileForm.form },
				tokens,
			});

			if (result && result.status) {
				// Profile updated successfully
				updateUserData(result.data);
				toast('Profile updated successfully!', {
					type: 'success',
					theme: 'colored',
				});
			}
		}
	};

	return (
		<div className='bv-edit-profile-page py-3 p-md-0'>
			<KrManagerNav />

			<div className='kr-manager-filter'>
				<button
					type='button'
					className='btn btn-autowidth btn-red btn-large'
					name='button'
				>
					Edit Profile
				</button>
			</div>

			<div className='container user-info-card mb-3'>
				<div className='card'>
					<div className='card-body d-flex align-items-center'>
						<div className='user-profile me-3'>
							<img
								src={
									profileForm.form.avatar
										? URL.createObjectURL(profileForm.form.avatar)
										: user_data?.avatar
										? encodeURI(user_data.avatar)
										: defaultImg
								}
								alt='avatar'
							/>

							<input
								className='form-control'
								type='file'
								ref={avatarSelectRef}
								onChange={handleFileSelected}
							/>

							<FontAwesomeIcon
								className='icon'
								icon={icon({ name: 'pencil', style: 'solid' })}
								onClick={() => avatarSelectRef.current?.click()}
							/>
						</div>

						<div className='user-info'>
							<div className='d-flex align-items-center'>
								<h5 className='mb-1'>{user_data?.email}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='kr-admin-table'>
				<form onSubmit={handleSubmit}>
					<div className='kr-admin-line kr-admin-line-cls'>
						<div className='kr-admin-field'>
							<div>
								<label>Referral Code</label>
							</div>
							<div>
								<input
									type='text'
									id='myInput'
									ref={inputRef}
									readOnly
									name='reference'
									value={user_data?.referral_code || ''}
									className=' form-control'
								/>
								<button className='btn btn-danger-light' onClick={myFunction}>
									Copy Referral Code
								</button>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>First Name</label>
							</div>
							<div>
								<input
									type='text'
									name='firstname'
									value={profileForm.form.firstname}
									onChange={handleChange}
									required
									placeholder='First Name'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Last Name </label>
							</div>
							<div>
								<input
									type='text'
									name='lastname'
									value={profileForm.form.lastname}
									onChange={handleChange}
									required
									placeholder='Last Name'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Email Address</label>
							</div>
							<div>
								<input
									type='text'
									name='email'
									value={user_data?.email || ''}
									readOnly
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Phone</label>
							</div>
							<div>
								<input
									type='text'
									name='phone'
									value={profileForm.form.phone || ''}
									onChange={handleChange}
									placeholder='Phone'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Gender</label>
							</div>
							<div>
								<select
									name='gender'
									placeholder='Male'
									className='form-control'
									value={profileForm.form.gender || ''}
									onChange={handleChange}
								>
									<option value='' selected disabled>
										Select Gender
									</option>
									<option value='male'>Male</option>
									<option value='female'>Female</option>
								</select>
							</div>
						</div>
					</div>

					<div className='kr-admin-line kr-admin-line-cls'>
						<div className='kr-admin-field'>
							<div>
								<label>Country</label>
							</div>
							<div>
								<select
									id='country'
									name='country'
									required
									value={profileForm.form.country || ''}
									onChange={handleChange}
								>
									<option value='' selected disabled>
										Select Country
									</option>
									<option value='United States of America'>
										United States of America
									</option>
									<option value='Afganistan'>Afghanistan</option>
									<option value='Albania'>Albania</option>
									<option value='Algeria'>Algeria</option>
									<option value='American Samoa'>American Samoa</option>
									<option value='Andorra'>Andorra</option>
									<option value='Angola'>Angola</option>
									<option value='Anguilla'>Anguilla</option>
									<option value='Antigua &amp; Barbuda'>
										Antigua &amp; Barbuda
									</option>
									<option value='Argentina'>Argentina</option>
									<option value='Armenia'>Armenia</option>
									<option value='Aruba'>Aruba</option>
									<option value='Australia'>Australia</option>
									<option value='Austria'>Austria</option>
									<option value='Azerbaijan'>Azerbaijan</option>
									<option value='Bahamas'>Bahamas</option>
									<option value='Bahrain'>Bahrain</option>
									<option value='Bangladesh'>Bangladesh</option>
									<option value='Barbados'>Barbados</option>
									<option value='Belarus'>Belarus</option>
									<option value='Belgium'>Belgium</option>
									<option value='Belize'>Belize</option>
									<option value='Benin'>Benin</option>
									<option value='Bermuda'>Bermuda</option>
									<option value='Bhutan'>Bhutan</option>
									<option value='Bolivia'>Bolivia</option>
									<option value='Bonaire'>Bonaire</option>
									<option value='Bosnia &amp; Herzegovina'>
										Bosnia &amp; Herzegovina
									</option>
									<option value='Botswana'>Botswana</option>
									<option value='Brazil'>Brazil</option>
									<option value='British Indian Ocean Ter'>
										British Indian Ocean Ter
									</option>
									<option value='Brunei'>Brunei</option>
									<option value='Bulgaria'>Bulgaria</option>
									<option value='Burkina Faso'>Burkina Faso</option>
									<option value='Burundi'>Burundi</option>
									<option value='Cambodia'>Cambodia</option>
									<option value='Cameroon'>Cameroon</option>
									<option value='Canada'>Canada</option>
									<option value='Canary Islands'>Canary Islands</option>
									<option value='Cape Verde'>Cape Verde</option>
									<option value='Cayman Islands'>Cayman Islands</option>
									<option value='Central African Republic'>
										Central African Republic
									</option>
									<option value='Chad'>Chad</option>
									<option value='Channel Islands'>Channel Islands</option>
									<option value='Chile'>Chile</option>
									<option value='China'>China</option>
									<option value='Christmas Island'>Christmas Island</option>
									<option value='Cocos Island'>Cocos Island</option>
									<option value='Colombia'>Colombia</option>
									<option value='Comoros'>Comoros</option>
									<option value='Congo'>Congo</option>
									<option value='Cook Islands'>Cook Islands</option>
									<option value='Costa Rica'>Costa Rica</option>
									<option value='Cote DIvoire'>Cote DIvoire</option>
									<option value='Croatia'>Croatia</option>
									<option value='Cuba'>Cuba</option>
									<option value='Curaco'>Curacao</option>
									<option value='Cyprus'>Cyprus</option>
									<option value='Czech Republic'>Czech Republic</option>
									<option value='Denmark'>Denmark</option>
									<option value='Djibouti'>Djibouti</option>
									<option value='Dominica'>Dominica</option>
									<option value='Dominican Republic'>Dominican Republic</option>
									<option value='East Timor'>East Timor</option>
									<option value='Ecuador'>Ecuador</option>
									<option value='Egypt'>Egypt</option>
									<option value='El Salvador'>El Salvador</option>
									<option value='Equatorial Guinea'>Equatorial Guinea</option>
									<option value='Eritrea'>Eritrea</option>
									<option value='Estonia'>Estonia</option>
									<option value='Ethiopia'>Ethiopia</option>
									<option value='Falkland Islands'>Falkland Islands</option>
									<option value='Faroe Islands'>Faroe Islands</option>
									<option value='Fiji'>Fiji</option>
									<option value='Finland'>Finland</option>
									<option value='France'>France</option>
									<option value='French Guiana'>French Guiana</option>
									<option value='French Polynesia'>French Polynesia</option>
									<option value='French Southern Ter'>
										French Southern Ter
									</option>
									<option value='Gabon'>Gabon</option>
									<option value='Gambia'>Gambia</option>
									<option value='Georgia'>Georgia</option>
									<option value='Germany'>Germany</option>
									<option value='Ghana'>Ghana</option>
									<option value='Gibraltar'>Gibraltar</option>
									<option value='Great Britain'>Great Britain</option>
									<option value='Greece'>Greece</option>
									<option value='Greenland'>Greenland</option>
									<option value='Grenada'>Grenada</option>
									<option value='Guadeloupe'>Guadeloupe</option>
									<option value='Guam'>Guam</option>
									<option value='Guatemala'>Guatemala</option>
									<option value='Guinea'>Guinea</option>
									<option value='Guyana'>Guyana</option>
									<option value='Haiti'>Haiti</option>
									<option value='Hawaii'>Hawaii</option>
									<option value='Honduras'>Honduras</option>
									<option value='Hong Kong'>Hong Kong</option>
									<option value='Hungary'>Hungary</option>
									<option value='Iceland'>Iceland</option>
									<option value='Indonesia'>Indonesia</option>
									<option value='India'>India</option>
									<option value='Iran'>Iran</option>
									<option value='Iraq'>Iraq</option>
									<option value='Ireland'>Ireland</option>
									<option value='Isle of Man'>Isle of Man</option>
									<option value='Israel'>Israel</option>
									<option value='Italy'>Italy</option>
									<option value='Jamaica'>Jamaica</option>
									<option value='Japan'>Japan</option>
									<option value='Jordan'>Jordan</option>
									<option value='Kazakhstan'>Kazakhstan</option>
									<option value='Kenya'>Kenya</option>
									<option value='Kiribati'>Kiribati</option>
									<option value='Korea North'>Korea North</option>
									<option value='Korea Sout'>Korea South</option>
									<option value='Kuwait'>Kuwait</option>
									<option value='Kyrgyzstan'>Kyrgyzstan</option>
									<option value='Laos'>Laos</option>
									<option value='Latvia'>Latvia</option>
									<option value='Lebanon'>Lebanon</option>
									<option value='Lesotho'>Lesotho</option>
									<option value='Liberia'>Liberia</option>
									<option value='Libya'>Libya</option>
									<option value='Liechtenstein'>Liechtenstein</option>
									<option value='Lithuania'>Lithuania</option>
									<option value='Luxembourg'>Luxembourg</option>
									<option value='Macau'>Macau</option>
									<option value='Macedonia'>Macedonia</option>
									<option value='Madagascar'>Madagascar</option>
									<option value='Malaysia'>Malaysia</option>
									<option value='Malawi'>Malawi</option>
									<option value='Maldives'>Maldives</option>
									<option value='Mali'>Mali</option>
									<option value='Malta'>Malta</option>
									<option value='Marshall Islands'>Marshall Islands</option>
									<option value='Martinique'>Martinique</option>
									<option value='Mauritania'>Mauritania</option>
									<option value='Mauritius'>Mauritius</option>
									<option value='Mayotte'>Mayotte</option>
									<option value='Mexico'>Mexico</option>
									<option value='Midway Islands'>Midway Islands</option>
									<option value='Moldova'>Moldova</option>
									<option value='Monaco'>Monaco</option>
									<option value='Mongolia'>Mongolia</option>
									<option value='Montserrat'>Montserrat</option>
									<option value='Morocco'>Morocco</option>
									<option value='Mozambique'>Mozambique</option>
									<option value='Myanmar'>Myanmar</option>
									<option value='Nambia'>Nambia</option>
									<option value='Nauru'>Nauru</option>
									<option value='Nepal'>Nepal</option>
									<option value='Netherland Antilles'>
										Netherland Antilles
									</option>
									<option value='Netherlands'>
										Netherlands (Holland, Europe)
									</option>
									<option value='Nevis'>Nevis</option>
									<option value='New Caledonia'>New Caledonia</option>
									<option value='New Zealand'>New Zealand</option>
									<option value='Nicaragua'>Nicaragua</option>
									<option value='Niger'>Niger</option>
									<option value='Nigeria'>Nigeria</option>
									<option value='Niue'>Niue</option>
									<option value='Norfolk Island'>Norfolk Island</option>
									<option value='Norway'>Norway</option>
									<option value='Oman'>Oman</option>
									<option value='Pakistan'>Pakistan</option>
									<option value='Palau Island'>Palau Island</option>
									<option value='Palestine'>Palestine</option>
									<option value='Panama'>Panama</option>
									<option value='Papua New Guinea'>Papua New Guinea</option>
									<option value='Paraguay'>Paraguay</option>
									<option value='Peru'>Peru</option>
									<option value='Phillipines'>Philippines</option>
									<option value='Pitcairn Island'>Pitcairn Island</option>
									<option value='Poland'>Poland</option>
									<option value='Portugal'>Portugal</option>
									<option value='Puerto Rico'>Puerto Rico</option>
									<option value='Qatar'>Qatar</option>
									<option value='Republic of Montenegro'>
										Republic of Montenegro
									</option>
									<option value='Republic of Serbia'>Republic of Serbia</option>
									<option value='Reunion'>Reunion</option>
									<option value='Romania'>Romania</option>
									<option value='Russia'>Russia</option>
									<option value='Rwanda'>Rwanda</option>
									<option value='St Barthelemy'>St Barthelemy</option>
									<option value='St Eustatius'>St Eustatius</option>
									<option value='St Helena'>St Helena</option>
									<option value='St Kitts-Nevis'>St Kitts-Nevis</option>
									<option value='St Lucia'>St Lucia</option>
									<option value='St Maarten'>St Maarten</option>
									<option value='St Pierre &amp; Miquelon'>
										St Pierre &amp; Miquelon
									</option>
									<option value='St Vincent &amp; Grenadines'>
										St Vincent &amp; Grenadines
									</option>
									<option value='Saipan'>Saipan</option>
									<option value='Samoa'>Samoa</option>
									<option value='Samoa American'>Samoa American</option>
									<option value='San Marino'>San Marino</option>
									<option value='Sao Tome &amp; Principe'>
										Sao Tome &amp; Principe
									</option>
									<option value='Saudi Arabia'>Saudi Arabia</option>
									<option value='Senegal'>Senegal</option>
									<option value='Seychelles'>Seychelles</option>
									<option value='Sierra Leone'>Sierra Leone</option>
									<option value='Singapore'>Singapore</option>
									<option value='Slovakia'>Slovakia</option>
									<option value='Slovenia'>Slovenia</option>
									<option value='Solomon Islands'>Solomon Islands</option>
									<option value='Somalia'>Somalia</option>
									<option value='South Africa'>South Africa</option>
									<option value='Spain'>Spain</option>
									<option value='Sri Lanka'>Sri Lanka</option>
									<option value='Sudan'>Sudan</option>
									<option value='Suriname'>Suriname</option>
									<option value='Swaziland'>Swaziland</option>
									<option value='Sweden'>Sweden</option>
									<option value='Switzerland'>Switzerland</option>
									<option value='Syria'>Syria</option>
									<option value='Tahiti'>Tahiti</option>
									<option value='Taiwan'>Taiwan</option>
									<option value='Tajikistan'>Tajikistan</option>
									<option value='Tanzania'>Tanzania</option>
									<option value='Thailand'>Thailand</option>
									<option value='Togo'>Togo</option>
									<option value='Tokelau'>Tokelau</option>
									<option value='Tonga'>Tonga</option>
									<option value='Trinidad &amp; Tobago'>
										Trinidad &amp; Tobago
									</option>
									<option value='Tunisia'>Tunisia</option>
									<option value='Turkey'>Turkey</option>
									<option value='Turkmenistan'>Turkmenistan</option>
									<option value='Turks &amp; Caicos Is'>
										Turks &amp; Caicos Is
									</option>
									<option value='Tuvalu'>Tuvalu</option>
									<option value='Uganda'>Uganda</option>
									<option value='United Kingdom'>United Kingdom</option>
									<option value='Ukraine'>Ukraine</option>
									<option value='United Arab Erimates'>
										United Arab Emirates
									</option>
									<option value='United States of America'>
										United States of America
									</option>
									<option value='Uraguay'>Uruguay</option>
									<option value='Uzbekistan'>Uzbekistan</option>
									<option value='Vanuatu'>Vanuatu</option>
									<option value='Vatican City State'>Vatican City State</option>
									<option value='Venezuela'>Venezuela</option>
									<option value='Vietnam'>Vietnam</option>
									<option value='Virgin Islands (Brit)'>
										Virgin Islands (Brit)
									</option>
									<option value='Virgin Islands (USA)'>
										Virgin Islands (USA)
									</option>
									<option value='Wake Island'>Wake Island</option>
									<option value='Wallis &amp; Futana Is'>
										Wallis &amp; Futana Is
									</option>
									<option value='Yemen'>Yemen</option>
									<option value='Zaire'>Zaire</option>
									<option value='Zambia'>Zambia</option>
									<option value='Zimbabwe'>Zimbabwe</option>
								</select>
								<span className='invalid-feedback'>
									<strong></strong>
								</span>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label> State/region </label>
							</div>
							<div>
								<input
									type='text'
									name='state'
									value={profileForm.form.state || ''}
									onChange={handleChange}
									required
									placeholder='State'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>City</label>
							</div>
							<div>
								<input
									type='text'
									name='city'
									value={profileForm.form.city || ''}
									onChange={handleChange}
									required
									placeholder='City'
								/>
							</div>
						</div>

						<div className='kr-admin-field'>
							<div>
								<label>Address</label>
							</div>
							<div>
								<input
									type='text'
									name='address'
									value={profileForm.form.address || ''}
									onChange={handleChange}
									required
									placeholder='Address'
								/>
							</div>
						</div>
					</div>

					{profileApiRequest.error && (
						<div className='mt-3 api-error alert alert-danger text-center'>
							{profileApiRequest.error}
						</div>
					)}

					<div className='kr-admin-action'>
						<input
							type='submit'
							disabled={profileApiRequest.loading}
							className='btn btn-orange'
							value='Update Profile'
						/>
					</div>
				</form>
			</div>

			<div className='container user-data-card'>
				<div className='card'>
					<div className='card-body'>
						<form onSubmit={handleSubmit}>
							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='portfolio'>
									Referral code
								</label>
								<input
									type='text'
									id='myInput'
									ref={inputRef}
									readOnly
									name='reference'
									value={user_data?.referral_code || ''}
									className=' form-control'
								/>
								<button className='btn btn-primary btn-sm' onClick={myFunction}>
									Copy Referral Code
								</button>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='firstname'>
									First Name
								</label>
								<input
									type='text'
									className=' form-control'
									name='firstname'
									onChange={handleChange}
									id='firstname'
									value={profileForm.form.firstname}
									required
									placeholder='First Name'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='lastname'>
									Last Name
								</label>
								<input
									type='text'
									className='form-control'
									name='lastname'
									id='lastname'
									value={profileForm.form.lastname}
									onChange={handleChange}
									required
									placeholder='Last Name'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='email'>
									Email Address
								</label>
								<input
									type='email'
									name='email'
									id='email'
									readOnly
									value={user_data?.email || ''}
									required
									placeholder='Email'
									className=' form-control'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='phone'>
									Phone
								</label>
								<input
									type='text'
									name='phone'
									id='phone'
									value={profileForm.form.phone || ''}
									onChange={handleChange}
									placeholder='Phone'
									className=' form-control'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='portfolio'>
									Gender
								</label>
								<select
									name='gender'
									placeholder=' male'
									className='form-control '
									id='gender'
								>
									<option value='male'>Male</option>
									<option value='female'>Female</option>
								</select>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='address'>
									Address
								</label>
								<input
									type='text'
									name='address'
									id='address'
									value={profileForm.form.address || ''}
									onChange={handleChange}
									required
									placeholder='Address'
									className=' form-control'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='city'>
									City
								</label>
								<input
									type='text'
									name='city'
									id='city'
									value={profileForm.form.city || ''}
									onChange={handleChange}
									required
									placeholder='City'
									className=' form-control'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='state'>
									State / Region
								</label>
								<input
									type='text'
									name='state'
									id='state'
									value={profileForm.form.state || ''}
									onChange={handleChange}
									required
									placeholder='state'
									className=' form-control'
								/>
							</div>

							<div className='form-group mb-3'>
								<label className='form-label' htmlFor='gender'>
									Gender
								</label>
								<select
									name='gender'
									className='form-control'
									id='gender'
									onChange={handleChange}
									value={profileForm.form.gender || ''}
								>
									<option value='' selected disabled>
										Select Gender
									</option>
									<option value='male'>Male</option>
									<option value='female'>Female</option>
								</select>
							</div>

							{profileApiRequest.error && (
								<div className='mt-3 api-error alert alert-danger text-center'>
									{profileApiRequest.error}
								</div>
							)}

							<button
								type='submit'
								className='btn btn-success w-100'
								disabled={profileApiRequest.loading}
							>
								Update Profile
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
