import React from 'react';
import { Deposit } from '../../../interfaces/Deposit';

interface DepositHistoryMobileProps {
	deposits: Deposit[];
}

const DepositHistoryMobile: React.FC<DepositHistoryMobileProps> = ({
	deposits,
}) => {
	return (
		<div className='bv-deposit-history-mobile'>
			<div className='mobile-table'>
				<div className='container'>
					<div className='element-heading mt-3'>
						<h6>Deposit Log</h6>
					</div>
				</div>

				<div className='container'>
					<div className='card'>
						<div className='card-body'>
							<table className='table mb-0'>
								<thead>
									<tr>
										<th scope='col'>ID</th>
										<th scope='col'>Amount</th>
										<th scope='col'>Time</th>
									</tr>
								</thead>
								<tbody>
									{deposits.length === 0 && (
										<tr>
											<td colSpan={5}> You don't have any deposit history!</td>
										</tr>
									)}

									{deposits.map((item) => (
										<tr key={item.deposit_id}>
											<td>{item.deposit_id}</td>
											<td>{item.amount}</td>
											<td>
												{new Date(item.createdAt).toISOString().split('T')[0]}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepositHistoryMobile;
