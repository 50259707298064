import produce from 'immer';
import { ActionConstants } from './action-constants';
import { ScreenActionTypes } from './action-types';

interface initialStateInterface {
	currentScreen: 'mobile' | 'desktop' | 'tablet';
	successAlert: string | null;
}

const initialState = {
	currentScreen: 'desktop',
	successAlert: null,
} as initialStateInterface;

export const screenStateReducer = (
	state: initialStateInterface = initialState,
	Action: ScreenActionTypes
): initialStateInterface => {
	return produce(state, (screen_state) => {
		switch (Action.type) {
			case ActionConstants.SET_CURRENT_SCREEN_STATE:
				screen_state.currentScreen = Action.payload;
				return screen_state;

			case ActionConstants.OPEN_SUCCESS:
				screen_state.successAlert = Action.payload;
				return screen_state;

			case ActionConstants.CLOSE_SUCCESS:
				screen_state.successAlert = null;
				return screen_state;

			default:
				return screen_state;
		}
	});
};
