import React, { useEffect } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector } from '../../hooks/useAppSelector';
import {
	useProfitActions,
	useTradingActions,
} from '../../hooks/useReduxActions';
import useApi from '../../hooks/useApi';
import { Trade, TradeMode } from '../../interfaces/trading';
import { BvTokens } from '../../interfaces/Auth';
import tradingService from '../../services/TradingService';
import { Profit } from '../../interfaces/Profit';
import profitService from '../../services/ProfitService';
import { Link } from 'react-router-dom';
import { AllRouteConstants } from '../../router/routes';

const get_trades = (data: { tokens: BvTokens; mode: TradeMode }) =>
	tradingService.get_user_trades(data.tokens, data.mode);

const get_profits = (tokens: BvTokens) => profitService.get_profits(tokens);

const KrLeftSide: React.FC = () => {
	const { tokens } = useAppSelector((state) => state.authReducer);

	const { trades } = useAppSelector((state) => state.tradingReducer);

	const { mode } = useAppSelector((state) => state.cachedReducer);

	const { setTrades } = useTradingActions();

	const getTradeApiRequest = useApi<
		{ data: Trade[] },
		{ tokens: BvTokens; mode: TradeMode }
	>(get_trades);

	useEffect(() => {
		if (tokens) {
			getTradeApiRequest.request({ tokens, mode }).then((data) => {
				if (data) {
					setTrades(data.data);
				}
			});
		}
	}, [tokens, mode]);

	const { profits, initialized } = useAppSelector(
		(state) => state.profitReducer
	);

	const getProfitsApiRequest = useApi<
		{ status: boolean; data: Profit[] },
		BvTokens
	>(get_profits);

	const { setProfits } = useProfitActions();

	useEffect(() => {
		if (!initialized && tokens) {
			getProfitsApiRequest.request(tokens).then((data) => {
				if (data && data.status) {
					setProfits(data.data);
				}
			});
		}
	}, [initialized, tokens]);

	return (
		<section className='kr-leftside'>
			<div className='kr-leftside-hide-controller'>
				<FontAwesomeIcon
					className='icon'
					size='sm'
					icon={icon({ name: 'chevron-left', style: 'solid' })}
				/>
			</div>

			<div className='kr-wtchl'>
				<header>
					<span>Profits</span>
					<div
						className='kr-search-field-content kr-search-field-content-inited'
						kr-search-callback='addWatchingListSearch'
						id='588185'
					>
						<Link to={AllRouteConstants.user.profit}>
							<button className='btn btn-autowidth btn-green btn-small'>
								Profit History
							</button>
						</Link>
					</div>
				</header>

				<ul className='kr-wtchl-lst ui-sortable'>
					<li
						kr-watchinglistpair='BINANCE:ETH/BTC'
						className='ui-sortable-handle'
					>
						<table className='crypto-table'>
							<tbody>
								<tr>
									<th>Amount</th>
									<th>Profits</th>
									<th>Earnings</th>
								</tr>

								{profits.length === 0 && (
									<tr>
										<td colSpan={5}> You have not Earned Yet</td>
									</tr>
								)}

								{profits.map((profit) => (
									<tr key={profit.profit_id}>
										<td>{profit.amount}</td>
										<td>{profit.profit}</td>
										<td>{profit.earnings}</td>
									</tr>
								))}
							</tbody>
						</table>
					</li>
				</ul>
			</div>

			<div className='kr-infoscurrencylf'>
				<header
					kr-leftinfoisp='BINANCE:ETHBTC'
					kr-leftinfois-makr='BINANCE'
					kr-leftinfois-symbol='ETH'
					kr-leftinfois-currency='BTC'
				>
					<span>Recent Trades</span>
					{/* <button type="button" className="btn btn-small btn-green btn-autowidth">Hide orders book</button> */}
				</header>

				<ul className='kr-wtchl-lst ui-sortable' id='tradehistory'>
					<li
						kr-watchinglistpair='BINANCE:ETH/BTC'
						className='ui-sortable-handle'
					>
						<table className='crypto-table'>
							<tbody>
								<tr>
									<th>Amount</th>
									<th>End Price</th>
									<th>Status</th>
								</tr>

								{trades.length === 0 && (
									<tr>
										<td colSpan={8}>
											<div className='alert alert-danger text-center text-bold'>
												No Trade in History Yet!
											</div>
										</td>
									</tr>
								)}

								{trades.slice(0, 2).map((trade) => (
									<tr key={trade.trade_id}>
										<td>{trade.amount}</td>
										<td>
											{typeof trade.end_price !== 'number'
												? '-'
												: trade.end_price}
										</td>
										<td>
											{trade.status === 'Pending'
												? 'In Progress'
												: trade.status}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</li>
				</ul>

				<div className='kr-infoscurrencylf-btn'>
					<Link to={AllRouteConstants.user.trade_history}>
						<button className='btn btn-autowidth btn-green btn-small'>
							Trade History
						</button>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default KrLeftSide;
