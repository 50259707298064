import React from "react";
import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import {
  Home,
  AboutUs,
  TradingPlans,
  ContactUs,
  BitcoinTrading,
  BitcoinCashTrading,
  LitecoinTrading,
  EtherumTrading,
  RippleTrading,
  FinancialInstruments,
  RiskDisclosure,
  TermsOfUse,
  PrivacyPolicy,
  LivePayouts,
  AMLPolicy,
  PaymentPolicy
} from "../pages";

const PublicRoutes = () => {
  return (
    <>
      <Route index element={<Home />} />
      <Route path={AllRouteConstants.main.about_us} element={<AboutUs />} />
      <Route path={AllRouteConstants.main.trading_plans} element={<TradingPlans />} />
      <Route path={AllRouteConstants.main.contact_us} element={<ContactUs />} />
      <Route path={AllRouteConstants.main.bitcoin_trading} element={<BitcoinTrading />} />
      <Route path={AllRouteConstants.main.bitcoin_cash_trading} element={<BitcoinCashTrading />} />
      <Route path={AllRouteConstants.main.litecoin_trading} element={<LitecoinTrading />} />
      <Route path={AllRouteConstants.main.etherum_trading} element={<EtherumTrading />} />
      <Route path={AllRouteConstants.main.ripple_trading} element={<RippleTrading />} />
      <Route path={AllRouteConstants.main.financial_instruments} element={<FinancialInstruments />} />
      <Route path={AllRouteConstants.main.risk_disclosure} element={<RiskDisclosure />} />
      <Route path={AllRouteConstants.main.terms_of_use} element={<TermsOfUse />} />
      <Route path={AllRouteConstants.main.privacy_policy} element={<PrivacyPolicy />} />
      <Route path={AllRouteConstants.main.live_payouts} element={<LivePayouts />} />
      <Route path={AllRouteConstants.main.aml_policy} element={<AMLPolicy />} />
      <Route path={AllRouteConstants.main.payment_policy} element={<PaymentPolicy />} />
    </>
  );
};

export default PublicRoutes;
