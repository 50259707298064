import React, { useEffect } from 'react';
import KrManagerNav from '../../../components/KrManagerNav';
import KrLeftSide from '../../../components/KrLeftside';
import WithdrawHistoryMobile from '../components/WithdrawHistoryMobile';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { BvTokens } from '../../../interfaces/Auth';
import withdrawService from '../../../services/WithdrawService';
import useApi from '../../../hooks/useApi';
import { Withdraw } from '../../../interfaces/Withdraw';
import { useWithdrawActions } from '../../../hooks/useReduxActions';
import { toast } from 'react-toastify';

const get_withdraws = (tokens: BvTokens) =>
	withdrawService.get_withdraw_history(tokens);

export const WithdrawLog: React.FC = () => {
	const { withdraws } = useAppSelector((state) => state.withdrawReducer);
	const { tokens } = useAppSelector((state) => state.authReducer);

	const getWithdrawApiRequest = useApi<
		{ status: boolean; data: Withdraw[] },
		BvTokens
	>(get_withdraws);

	const { setWithdraws } = useWithdrawActions();

	useEffect(() => {
		if (tokens) {
			getWithdrawApiRequest.request(tokens).then((data) => {
				if (data && data.status) {
					setWithdraws(data.data);
				}
			});
		}
	}, [tokens]);

	useEffect(() => {
		if (getWithdrawApiRequest.error) {
			toast(getWithdrawApiRequest.error, { theme: 'colored', type: 'error' });
		}
	}, [getWithdrawApiRequest.error]);

	return (
		<>
			<div className='bv-withdraw-log-page'>
				<KrLeftSide />

				<div className='w-full'>
					<KrManagerNav />

					<div className='kr-manager-filter'>
						<button
							type='button'
							className='btn btn-autowidth btn-red btn-large'
							name='button'
						>
							Withdrawal History
						</button>
					</div>

					<div className='kr-admin-table'>
						<table>
							<thead>
								<tr>
									<td>S/N</td>
									<td>Transaction ID</td>
									<td>Wallet</td>
									<td>Amount</td>
									<td>Status</td>
								</tr>
							</thead>
							<tbody>
								{withdraws.length === 0 && (
									<tr>
										<td colSpan={3}>You don't have any withdraw history !!</td>
									</tr>
								)}

								{withdraws.map((item, idx) => (
									<tr key={item.withdraw_id}>
										<td>{idx + 1}</td>
										<td>{item.withdraw_id}</td>
										<td>{item.wallet}</td>
										<td>{item.amount}</td>
										<td>{item.status}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<WithdrawHistoryMobile withdraws={withdraws} />
		</>
	);
};
