import produce from 'immer';
import { ActionConstants } from './action-constants';
import { CachedActionTypes } from './action-types';
import { TradeMode } from '../../interfaces/trading';

interface initialStateInterface {
	mode: TradeMode;
}

const initialState: initialStateInterface = {
	mode: 'real',
};

export const cachedReducer = (
	state: initialStateInterface = initialState,
	Action: CachedActionTypes
): initialStateInterface => {
	return produce(state, (cached) => {
		switch (Action.type) {
			case ActionConstants.CHANGE_TRADE_MODE:
				cached.mode = cached.mode === 'demo' ? 'real' : 'demo';
				return cached;

			case ActionConstants.SET_TRADE_MODE:
				cached.mode = Action.payload;
				return cached;

			default:
				return cached;
		}
	});
};
