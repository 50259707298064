import axios from 'axios';
import { API_URL } from '../config/env';
import { BvTokens } from '../interfaces/Auth';

class ProfitService {
	get_profits = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/profit`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const profitService = new ProfitService();
export default profitService;
