import produce from 'immer';
import { ActionConstants } from './action-constants';
import { RedirectActionTypes } from './action-types';

interface initialStateInterface {
	auth_redirect_route: string | null;
}

const initialState: initialStateInterface = {
	auth_redirect_route: null,
};

export const redirectReducer = (
	state: initialStateInterface = initialState,
	Action: RedirectActionTypes
): initialStateInterface => {
	return produce(state, (redirect_state) => {
		switch (Action.type) {
			case ActionConstants.SET_AUTH_REDIRECT:
				redirect_state.auth_redirect_route = Action.payload;
				return redirect_state;

			default:
				return redirect_state;
		}
	});
};
