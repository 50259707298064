import React, { useEffect } from 'react';
import KrManagerNav from '../../../components/KrManagerNav';
import TradingHistoryMobile from '../components/TradingHistoryMobile';
import { BvTokens } from '../../../interfaces/Auth';
import { Trade, TradeMode } from '../../../interfaces/trading';
import tradingService from '../../../services/TradingService';
import useApi from '../../../hooks/useApi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useTradingActions } from '../../../hooks/useReduxActions';

const get_trades = (data: { tokens: BvTokens; mode: TradeMode }) =>
	tradingService.get_user_trades(data.tokens, data.mode);

export const TradingHistory: React.FC = () => {
	const { tokens } = useAppSelector((state) => state.authReducer);

	const { mode } = useAppSelector((state) => state.cachedReducer);

	const { trades } = useAppSelector((state) => state.tradingReducer);

	const { setTrades } = useTradingActions();

	const getTradeApiRequest = useApi<
		{ data: Trade[] },
		{ tokens: BvTokens; mode: TradeMode }
	>(get_trades);

	useEffect(() => {
		if (tokens) {
			getTradeApiRequest.request({ tokens, mode }).then((data) => {
				if (data) {
					setTrades(data.data);
				}
			});
		}
	}, [tokens, mode]);

	return (
		<>
			<div className='bv-trading-history-page'>
				<KrManagerNav />

				<div className='kr-manager-filter'>
					<button
						type='button'
						className='btn btn-autowidth btn-red btn-large'
						name='button'
					>
						Trade History
					</button>
				</div>

				<div className='kr-admin-table' id='tradehistory'>
					<table>
						<thead>
							<tr>
								<td>Order ID</td>
								<td>Trade Time</td>
								<td>Symbol</td>
								<td>Amount</td>
								<td>Strike Rate</td>
								<td>End Price</td>
								<td>End Time</td>
								<td>Status</td>
								<td>Trading Type</td>
							</tr>
						</thead>

						<tbody>
							{trades.length === 0 && (
								<tr>
									<td colSpan={8}>
										<div className='alert alert-danger text-center text-bold'>
											No Trade in History Yet!
										</div>
									</td>
								</tr>
							)}

							{trades.map((trade) => (
								<tr id={trade.trade_id}>
									<td>{trade.trade_id}</td>
									<td>
										{
											new Date(trade.trade_time)
												.toISOString()
												.replace('T', ' ')
												.split('.')[0]
										}
									</td>
									<td>{trade.symbol}</td>
									<td>{trade.amount}</td>
									<td>{trade.strike_rate}</td>
									<td>
										{typeof trade.end_price !== 'number'
											? '-'
											: trade.end_price}
									</td>
									<td>
										{
											new Date(trade.end_time)
												.toISOString()
												.replace('T', ' ')
												.split('.')[0]
										}
									</td>
									<td>
										{trade.status === 'Pending' ? 'In Progress' : trade.status}
									</td>
									<td>{trade.trading_type}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<TradingHistoryMobile trades={trades} />
		</>
	);
};
