import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AllRouteConstants } from '../../router/routes';
import { useAppSelector } from '../../hooks/useAppSelector';

const MobileFooter: React.FC = () => {
	const { pathname } = useLocation();

	const { mode } = useAppSelector((state) => state.cachedReducer);

	return (
		<div className='footer-nav-area'>
			<div className='container px-0'>
				<div className='footer-nav position-relative'>
					<ul className='h-100 d-flex align-items-center justify-content-between ps-0'>
						<li
							className={
								pathname === AllRouteConstants.user.home ? 'active' : ''
							}
						>
							<Link to={AllRouteConstants.user.home}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									fill='currentColor'
									className='bi bi-graph-up'
									viewBox='0 0 16 16'
								>
									<path
										fill-rule='evenodd'
										d='M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z'
									></path>
								</svg>
								<span>Quick Trading</span>
							</Link>
						</li>

						{mode === 'real' && (
							<li
								className={
									pathname === AllRouteConstants.user.profit ? 'active' : ''
								}
							>
								<Link to={AllRouteConstants.user.profit}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='currentColor'
										className='bi bi-cash'
										viewBox='0 0 16 16'
									>
										<path d='M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'></path>
										<path d='M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z'></path>
									</svg>
									<span>Profits</span>
								</Link>
							</li>
						)}

						{mode === 'real' && (
							<li
								className={
									pathname === AllRouteConstants.user.deposit_fund
										? 'active'
										: ''
								}
							>
								<Link to={AllRouteConstants.user.deposit_fund}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='currentColor'
										className='bi bi-wallet'
										viewBox='0 0 16 16'
									>
										<path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z'></path>
									</svg>
									<span>Deposit</span>
								</Link>
							</li>
						)}

						{mode === 'real' && (
							<li
								className={
									pathname === AllRouteConstants.user.withdraw_fund
										? 'active'
										: ''
								}
							>
								<Link to={AllRouteConstants.user.withdraw_fund}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='currentColor'
										className='bi bi-arrow-bar-down'
										viewBox='0 0 16 16'
									>
										<path
											fill-rule='evenodd'
											d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z'
										></path>
									</svg>
									<span>Withdraw</span>
								</Link>
							</li>
						)}

						{mode === 'real' && (
							<li
								className={
									pathname === AllRouteConstants.user.plan ? 'active' : ''
								}
							>
								<Link to={AllRouteConstants.user.plan}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='currentColor'
										className='bi bi-card-list'
										viewBox='0 0 16 16'
									>
										<path d='M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'></path>
										<path d='M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z'></path>
									</svg>
									<span>Trading Plans</span>
								</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MobileFooter;
