import React from 'react';
import PopModal from '../../../../components/ModelLayout/ModalLayout';
import './styles.scss';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useForm } from '../../../../hooks/useForm';
import { Withdraw, WithdrawInput } from '../../../../interfaces/Withdraw';
import { emptyValidator } from '../../../../utils/validators/emptyValidator';
import { toast } from 'react-toastify';
import { BvTokens } from '../../../../interfaces/Auth';
import withdrawService from '../../../../services/WithdrawService';
import useApi from '../../../../hooks/useApi';
import {
	useScreenActions,
	useWithdrawActions,
} from '../../../../hooks/useReduxActions';

interface WithdrawRequestProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

const withdraw = (data: { tokens: BvTokens; data: WithdrawInput }) =>
	withdrawService.submit_withdraw(data.tokens, data.data);

const WithdrawRequest: React.FC<WithdrawRequestProps> = ({
	isOpen,
	onClose,
}) => {
	const withdrawForm = useForm<WithdrawInput>(
		{
			amount: '',
			wallet: '',
		},
		{ amount: emptyValidator, wallet: emptyValidator }
	);

	const withdrawApiRequest = useApi<
		{ status: boolean; data: Withdraw; message: string },
		{ tokens: BvTokens; data: WithdrawInput }
	>(withdraw);

	const { user_data, tokens } = useAppSelector((state) => state.authReducer);

	const { addWithdraw } = useWithdrawActions();
	const { toggleSuccessAlert } = useScreenActions();

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (!user_data || !withdrawForm.form.amount || !tokens) return;

		withdrawForm.resetFormErrors();

		if (withdrawForm.validate()) {
			console.log(user_data);
			if (withdrawForm.form.amount > user_data.balance) {
				toast('Cannot withraw more than available balance', {
					theme: 'colored',
					type: 'error',
				});
			} else {
				const res = await withdrawApiRequest.request({
					tokens,
					data: withdrawForm.form,
				});

				if (res) {
					addWithdraw(res.data);
					onClose();
					toggleSuccessAlert(res.message);
				}
			}
		}
	};

	return (
		<PopModal modalToggler={isOpen} onClose={() => {}}>
			<div className='bv-btc-deposit-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h4 className='modal-title'>
								{/* Deposit via <strong>Bitcoin</strong> */}
							</h4>
							<button type='button' className='close' onClick={onClose}>
								×
							</button>
						</div>

						<form autoComplete='off' onSubmit={handleSubmit}>
							<div className='modal-body'>
								<label className='col-md-12 modal-msg-heading'>
									<strong>Amount To Withdraw</strong>
								</label>

								<hr />

								<div className='form-group'>
									<div className='input-group mb-3'>
										<input
											type='number'
											name='amount'
											className='form-control input-lg'
											id='amount'
											placeholder='Enter Amount'
											required
											min={1}
											value={withdrawForm.form.amount}
											onChange={(e) =>
												withdrawForm.onChange('amount', e.target.valueAsNumber)
											}
										/>

										<div className='input-group-prepend'>
											<span className='input-group-text'>USD</span>
										</div>
									</div>

									<div className='input-group'>
										<input
											type='texg'
											name='withdraw'
											className='form-control input-lg'
											id='wallet'
											placeholder='Enter Wallet Address'
											required
											value={withdrawForm.form.wallet}
											onChange={(e) =>
												withdrawForm.onChange('wallet', e.target.value)
											}
										/>

										<div className='input-group-prepend'>
											<span className='input-group-text'>Wallet</span>
										</div>
									</div>
								</div>
							</div>

							<div className='modal-footer'>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={withdrawApiRequest.loading}
								>
									Submit
								</button>

								<button
									type='button'
									className='btn btn-default'
									onClick={onClose}
								>
									Close{' '}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</PopModal>
	);
};

export default WithdrawRequest;
