import React, { useEffect, useRef } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrManagerNav from '../../../components/KrManagerNav';
import profileService from '../../../services/ProfileService';
import { BvTokens, UserIdentificationInput } from '../../../interfaces/Auth';
import useApi from '../../../hooks/useApi';
import { ProfileVerifyData } from '../../../interfaces/Profile';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
	useProfileActions,
	useScreenActions,
} from '../../../hooks/useReduxActions';
import { useForm } from '../../../hooks/useForm';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import authService from '../../../services/AuthService';
import formatEnum from '../../../utils/formatEnum';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const save_identification = (data: {
	data: UserIdentificationInput;
	tokens: BvTokens;
}) => authService.save_identification_verif(data.tokens, data.data);

const get_verif = (tokens: BvTokens) =>
	profileService.get_user_verification(tokens);

export const Verify: React.FC = () => {
	const { tokens, user_data } = useAppSelector((state) => state.authReducer);

	const verifRequestApi = useApi<
		{ status: DecodeSuccessCallback; data: ProfileVerifyData },
		BvTokens
	>(get_verif);

	const { setProfileVerifyData } = useProfileActions();

	const { toggleSuccessAlert } = useScreenActions();

	const { verifyData } = useAppSelector((state) => state.profileReducer);

	useEffect(() => {
		if (tokens) {
			verifRequestApi.request(tokens).then((data) => {
				setProfileVerifyData(data?.data);
			});
		}
	}, [tokens]);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const identificationApiRequest = useApi<
		{ status: boolean },
		{ data: UserIdentificationInput; tokens: BvTokens }
	>(save_identification);

	const identificationForm = useForm<UserIdentificationInput>(
		{
			method: '',
			id_file: null,
		},
		{
			method: emptyValidator,
		}
	);

	const handleChange: React.ChangeEventHandler<
		HTMLSelectElement | HTMLTextAreaElement
	> = (e) => {
		identificationForm.onChange(
			e.target.name as keyof UserIdentificationInput,
			e.target.value
		);
	};

	const handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		if (e.target.files) {
			const file = e.target.files[0];

			if (file.size > MAX_FILE_SIZE) {
				alert('Max file size is 5mb');
				return;
			}

			identificationForm.onChange('id_file', e.target.files[0]);
		}
	};

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		identificationForm.resetFormErrors();
		identificationApiRequest.reset();

		const valid = identificationForm.validate();

		console.log(valid);

		if (valid && tokens) {
			const result = await identificationApiRequest.request({
				data: identificationForm.form,
				tokens,
			});

			if (result) {
				identificationForm.reset();
				verifRequestApi.request(tokens).then((data) => {
					setProfileVerifyData(data?.data);
				});
				toggleSuccessAlert('Government ID Submitted Successfully!');
			}
		}
	};

	return (
		<div className='bv-verify-page py-3 py-lg-0'>
			<KrLeftSide />

			<div className='flex-1'>
				<KrManagerNav />

				<div className='kr-manager-filter'>
					<input
						ref={inputFileRef}
						onChange={handleFileSelected}
						type='file'
						hidden
					/>
					<button
						type='button'
						disabled={Boolean(verifyData && verifyData.status !== 'Rejected')}
						className='btn btn-autowidth btn-red btn-large'
						name='button'
						onClick={() => inputFileRef.current?.click()}
					>
						Verify ID for{' '}
						{`${user_data?.firstname || ''} ${user_data?.lastname || ''}`}
					</button>
				</div>

				<div className='kr-admin-table'>
					<div className='kr-admin-line kr-admin-line-cls'>
						<div className='kr-admin-field'>
							<div>
								<label>ID Verification </label>
							</div>
							<div></div>
						</div>
						<div className='kr-admin-line kr-admin-line-cls'>
							{verifyData || identificationForm.form.id_file ? (
								<form className='kr-admin-field' onSubmit={handleSubmit}>
									<div>
										<label>
											{formatEnum(
												identificationForm.form.method ||
													verifyData?.method ||
													'...'
											)}
										</label>
										<br />
										<span>
											Uploaded ID Verification for{' '}
											{`${user_data?.firstname || ''} ${
												user_data?.lastname || ''
											}`}
										</span>
										<br />
										<br />
										<span style={{ fontWeight: 'bold' }}>
											Max upload size : 5 MB
										</span>
									</div>

									<div>
										{identificationForm.form.id_file ? (
											<img
												style={{ maxWidth: 300, maxHeight: 300 }}
												src={URL.createObjectURL(
													identificationForm.form.id_file
												)}
												alt=''
											/>
										) : verifyData ? (
											<img
												style={{ maxWidth: 300, maxHeight: 300 }}
												src={encodeURI(verifyData.id_file_url)}
												alt=''
											/>
										) : null}

										{identificationForm.form.id_file && (
											<div className='mb-3 mt-3'>
												<label className='form-label' htmlFor='method'>
													Method of Verification
												</label>

												<select
													id='method'
													name='method'
													className='form-control'
													required
													value={identificationForm.form.method}
													onChange={handleChange}
												>
													<option disabled selected value=''>
														Select Method of Verification
													</option>
													<option value='drivers_license'>
														{' '}
														Drivers License{' '}
													</option>
													<option value='international_passport'>
														{' '}
														International Passport{' '}
													</option>
													<option value='national_id'> National ID </option>
													<option value='others'> Other </option>
												</select>
											</div>
										)}

										<br />
										<br />

										{identificationForm.form.id_file ? (
											<button
												type='submit'
												disabled={identificationApiRequest.loading}
												className='btn btn-lg btn-success'
											>
												Submit
											</button>
										) : verifyData ? (
											<button type='button' className='btn btn-lg btn-success'>
												Status: {verifyData.status}
											</button>
										) : null}
									</div>
								</form>
							) : (
								'No Verification Document Submitted Yet!'
							)}
						</div>
					</div>
				</div>

				<div className='container kr-admin-table-mobile'>
					<div className='card'>
						<div className='card-body'>
							<center>
								<h2>
									Verification of ID for{' '}
									{`${user_data?.firstname || ''} ${user_data?.lastname || ''}`}
									<br />
									Status:
									<button type='button' className='btn btn-lg btn-success'>
										{verifyData?.status || '...'}
									</button>
								</h2>
							</center>

							<form className='user-profile me-3' onSubmit={handleSubmit}>
								<h6>
									{formatEnum(
										identificationForm.form.method ||
											verifyData?.method ||
											'...'
									)}
								</h6>

								{identificationForm.form.id_file ? (
									<img
										style={{ maxWidth: 300, maxHeight: 300 }}
										src={URL.createObjectURL(identificationForm.form.id_file)}
										alt=''
									/>
								) : verifyData ? (
									<img
										style={{ maxWidth: 300, maxHeight: 300 }}
										src={encodeURI(verifyData.id_file_url)}
										alt=''
									/>
								) : null}

								{identificationForm.form.id_file && (
									<div className='mb-3 mt-3'>
										<label className='form-label' htmlFor='method'>
											Method of Verification
										</label>

										<select
											id='method'
											name='method'
											className='form-control'
											required
											value={identificationForm.form.method}
											onChange={handleChange}
										>
											<option disabled selected value=''>
												Select Method of Verification
											</option>
											<option value='drivers_license'> Drivers License </option>
											<option value='international_passport'>
												{' '}
												International Passport{' '}
											</option>
											<option value='national_id'> National ID </option>
											<option value='others'> Other </option>
										</select>
									</div>
								)}

								{identificationForm.form.id_file && (
									<button
										type='submit'
										disabled={identificationApiRequest.loading}
										className='btn btn-lg btn-success'
									>
										Submit
									</button>
								)}

								{/* <div className='row'>
									<div className='form-group col-md-6'>
										<label>
											<strong>
												{verifyData?.status || "Pending"}
											</strong>
										</label>
									</div>
								</div> */}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
