import React from 'react';
import { Trade } from '../../../interfaces/trading';

interface TradingHistoryMobileProps {
	trades: Trade[];
}

const TradingHistoryMobile: React.FC<TradingHistoryMobileProps> = ({
	trades,
}) => {
	return (
		<div className='bv-trading-history-mobile'>
			<div className='mobile-table'>
				<div className='container'>
					<div className='element-heading mt-3'>
						<h6>Live Trade History</h6>
					</div>
				</div>

				<div className='container'>
					<div className='card'>
						<div className='card-body'>
							<table className='table mb-0'>
								<thead>
									<tr>
										<th scope='col'>Amount</th>
										<th scope='col'>End Price</th>
										<th scope='col'>Status</th>
									</tr>
								</thead>
								<tbody>
									{trades.length === 0 && (
										<tr>
											<td colSpan={5}> No Trade in History Yet</td>
										</tr>
									)}

									{trades.map((trade) => (
										<tr id={trade.trade_id}>
											<td>{trade.amount}</td>
											<td>
												{typeof trade.end_price !== 'number'
													? '-'
													: trade.end_price}
											</td>
											<td>
												{trade.status === 'Pending'
													? 'In Progress'
													: trade.status}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradingHistoryMobile;
