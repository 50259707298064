import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectProps {
	path: string;
}

const Redirect: React.FC<RedirectProps> = ({ path }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (path) {
			navigate(path);
		}
	}, [path]);

	return null;
};

export default Redirect;
