import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { RedirectActionTypes } from './action-types';

export const setAuthRedirect =
	(route?: string) => (dispatch: Dispatch<RedirectActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_AUTH_REDIRECT,
			payload: route || null,
		});
	};
