import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { ScreenActionTypes } from './action-types';

export const setScreenSize =
	(value: 'desktop' | 'tablet' | 'mobile') =>
	(dispatch: Dispatch<ScreenActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_CURRENT_SCREEN_STATE,
			payload: value,
		});
	};

export const toggleSuccessAlert =
	(data?: string) => (dispatch: Dispatch<ScreenActionTypes>) => {
		if (!data || typeof data !== 'string') {
			dispatch({
				type: ActionConstants.CLOSE_SUCCESS,
			});
		} else {
			dispatch({
				type: ActionConstants.OPEN_SUCCESS,
				payload: data,
			});
		}
	};
