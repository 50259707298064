import React from 'react';
import './styles.scss';
import PopModal from '../../../components/ModelLayout/ModalLayout';
import { Link, useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { useAuthActions } from '../../../hooks/useReduxActions';
import { useForm } from '../../../hooks/useForm';
import { BvTokens, LoginInput } from '../../../interfaces/Auth';
import { emailValidator } from '../../../utils/validators/emailValidator';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import useApi from '../../../hooks/useApi';
import authService from '../../../services/AuthService';
import { User } from '../../../interfaces/User';

interface LoginModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

const login = (data: LoginInput) => authService.login(data);

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
	const navigate = useNavigate();

	const { signIn } = useAuthActions();

	const loginForm = useForm<LoginInput>(
		{
			email: '',
			password: '',
		},
		{
			email: emailValidator,
			password: emptyValidator,
		}
	);

	const loginApiRequest = useApi<
		{ status: boolean; message: string; data: User; tokens: BvTokens },
		LoginInput
	>(login);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		loginForm.onChange(e.target.name as keyof LoginInput, e.target.value);
	};

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (loginApiRequest.loading) {
			return;
		}

		loginApiRequest.reset();
		loginForm.resetFormErrors();

		const valid = loginForm.validate();

		if (valid) {
			const result = await loginApiRequest.request(loginForm.form);

			if (result && result.status) {
				signIn({
					user: result.data,
					tokens: result.tokens,
				});
				navigate(AllRouteConstants.user.home);
			}
		}
	};

	return (
		<PopModal modalToggler={isOpen} onClose={onClose}>
			<div className='bv-login-modal'>
				<div className='nav mb-3'>
					<div className='nav-item'>Existing User</div>

					<div
						className='nav-item'
						onClick={() => navigate(AllRouteConstants.auth.signup)}
					>
						Register
					</div>
				</div>

				<div className='content'>
					<h5 className='modal-title'>Login</h5>

					<form
						name='loginForm'
						autoComplete='off'
						noValidate
						onSubmit={handleSubmit}
					>
						<div className='mb-30'>
							<label htmlFor='recipient-name2' className='col-form-label'>
								Email
							</label>
							<input
								type='text'
								name='email'
								value={loginForm.form.email}
								onChange={handleChange}
								className='form-control focus-input100'
								required
								placeholder='Type Email'
							/>
						</div>

						<div>
							<label htmlFor='recipient-name' className='col-form-label'>
								<span>Password</span>
								<Link
									className='forgot-btn'
									to={AllRouteConstants.auth.forgot_password}
								>
									Forgot Password?
								</Link>
							</label>
							<input
								type='password'
								required
								value={loginForm.form.password}
								onChange={handleChange}
								name='password'
								className='form-control'
								placeholder='Enter Password'
							/>
						</div>

						{loginApiRequest.error && (
							<div className='mt-3 api-error alert alert-danger text-center'>
								{loginApiRequest.error}
							</div>
						)}

						<button
							name='submit'
							type='submit'
							className='login-submit'
							disabled={loginApiRequest.loading}
						>
							Login
						</button>
					</form>
				</div>
			</div>
		</PopModal>
	);
};

export default LoginModal;
