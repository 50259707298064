import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	authActions,
	profileActions,
	screenActions,
	tradingActions,
	depositActions,
	withdrawActions,
	cachedActions,
	profitActions,
	redirectActions,
} from '../redux/allActions';

export const useAuthActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, authActions), dispatch);
};

export const useScreenActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, screenActions), dispatch);
};

export const useTradingActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, tradingActions), dispatch);
};

export const useProfileActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, profileActions), dispatch);
};

export const useDepositActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, depositActions), dispatch);
};

export const useWithdrawActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, withdrawActions), dispatch);
};

export const useCachedActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, cachedActions), dispatch);
};

export const useProfitActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, profitActions), dispatch);
};

export const useRedirectActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, redirectActions), dispatch);
};
