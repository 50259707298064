export const phoneValidator = (data: any) => {
	if (!data) {
		return 'must not be empty';
	}

	if (data.length < 10) {
		return 'must be at least 10 characters long';
	}

	return null;
};
