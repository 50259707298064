import React, { useEffect, useState } from 'react';
import KrManagerNav from '../../../components/KrManagerNav';
import KrLeftSide from '../../../components/KrLeftside';
import DepositHistoryMobile from '../components/DepositHistoryMobile';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { BvTokens } from '../../../interfaces/Auth';
import depositService from '../../../services/DepositService';
import useApi from '../../../hooks/useApi';
import { useDepositActions } from '../../../hooks/useReduxActions';
import { toast } from 'react-toastify';
import { convert } from '../../../convertCurrency';

const get_deposit_log = (tokens: BvTokens) =>
	depositService.get_deposit_history(tokens);

export const DepositLog: React.FC = () => {
	const [convertInitiated, setConvertInitiated] = useState(false);
	const { tokens } = useAppSelector((state) => state.authReducer);
	const { deposits } = useAppSelector((state) => state.depositReducer);

	const depositLogApiRequest = useApi<
		{ status: boolean; data: any[] },
		BvTokens
	>(get_deposit_log);

	useEffect(() => {
		const run = async () => {
			await convert.ready();
			setConvertInitiated(true);
		};

		run();
	}, []);

	useEffect(() => {
		if (depositLogApiRequest.error)
			toast(depositLogApiRequest.error, {
				type: 'error',
				theme: 'colored',
			});
	}, [depositLogApiRequest.error]);

	const { setDeposits } = useDepositActions();
	useEffect(() => {
		if (tokens) {
			depositLogApiRequest.request(tokens).then((data) => {
				setDeposits(data?.data || []);
			});
		}
	}, [tokens]);

	const convertUsd = (usdAmount: number) => {
		if (!usdAmount) {
			return 0;
		} else {
			return convert.USD?.BTC(usdAmount) || 0;
		}
	};

	return (
		<>
			<div className='bv-deposit-log-page'>
				<KrLeftSide />

				<div className='w-full'>
					<KrManagerNav />

					<div className='kr-manager-filter'>
						<button
							type='button'
							className='btn btn-autowidth btn-red btn-large'
							name='button'
						>
							Deposit History
						</button>
					</div>

					<div className='kr-admin-table'>
						<table>
							<thead>
								<tr>
									<td>S/N</td>
									<td>Transaction ID</td>
									<td>Amount Deposited</td>
									<td>Amount in BTC</td>
									<td>Status</td>
									<td>Date</td>
								</tr>
							</thead>
							<tbody>
								{deposits.length === 0 && (
									<tr>
										<td colSpan={5}>You don't have any deposit history !!</td>
									</tr>
								)}

								{convertInitiated &&
									deposits.map((item, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{item.deposit_id}</td>
											<td>{item.amount}</td>
											<td>{convertUsd(item.amount)}</td>
											<td>{item.status}</td>
											<td>
												{new Date(item.createdAt).toISOString().split('T')[0]}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<DepositHistoryMobile deposits={deposits} />
		</>
	);
};
