import React from 'react';
import { useEffect, useState } from 'react';
import Router from './router/Router';
import 'animate.css';
import { useAuthActions, useScreenActions } from './hooks/useReduxActions';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userService from './services/UserService';
import { BvTokens } from './interfaces/Auth';
import useApi from './hooks/useApi';
import { useAppSelector } from './hooks/useAppSelector';
import { User } from './interfaces/User';

const get_me = (tokens: BvTokens) => userService.get_me(tokens);

function App() {
	const [screen, setScreen] = useState<number | null>(null);
	const { setScreenSize } = useScreenActions();
	const { updateUserData } = useAuthActions();

	const { tokens } = useAppSelector((state) => state.authReducer);

	const getMeApiRequest = useApi<{ data: User }, BvTokens>(get_me);

	useEffect(() => {
		if (tokens) {
			getMeApiRequest.request(tokens).then((data) => {
				if (data) {
					console.log(data.data);
					updateUserData(data.data);
				}
			});
		}
	}, [tokens]);

	// Setting the Screen Size State whenever the screen is resized
	useEffect(() => {
		const handleResize = () => setScreen(window.innerWidth);
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		AOS.init();
	}, []);

	// Toggling the isMobile State whenever the screensize changes
	useEffect(() => {
		if (screen! <= 768) {
			setScreenSize('mobile');
		} else if (screen! > 768 && screen! < 1200) {
			setScreenSize('tablet');
		} else if (screen! > 1200) {
			setScreenSize('desktop');
		}
	}, [screen]);

	return (
		<>
			<ToastContainer />
			<Router />
		</>
	);
}

export default App;
