import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { DepositActionTypes } from './action-types';
import { Deposit } from '../../interfaces/Deposit';

export const setDeposits =
	(data: Deposit[]) => (dispatch: Dispatch<DepositActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_DEPOSITS,
			payload: data,
		});
	};

export const addDeposit =
	(data: Deposit) => (dispatch: Dispatch<DepositActionTypes>) => {
		dispatch({
			type: ActionConstants.ADD_DEPOSIT,
			payload: data,
		});
	};
