import React, { useEffect, useState } from 'react';
import PopModal from '../../../../components/ModelLayout/ModalLayout';
import './styles.scss';
import { convert } from '../../../../convertCurrency';
import { BvTokens } from '../../../../interfaces/Auth';
import depositService from '../../../../services/DepositService';
import { DepositInput } from '../../../../interfaces/Deposit';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useApi from '../../../../hooks/useApi';
import { useScreenActions } from '../../../../hooks/useReduxActions';
import { toast } from 'react-toastify';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

interface EthDepositProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

const get_wallet_address = (data: {
	tokens: BvTokens;
	currency: 'btc' | 'eth';
}) => depositService.get_address_for_deposit(data.tokens, data.currency);

const submit_deposit = (data: { tokens: BvTokens; data: DepositInput }) =>
	depositService.submit_deposit(data.tokens, data.data);

const EthDeposit: React.FC<EthDepositProps> = ({ isOpen, onClose }) => {
	const { tokens } = useAppSelector((state) => state.authReducer);
	const [step, setStep] = useState(1);
	const [walletAddress, setWalletAddress] = useState('');

	const [usdAmount, setUsdAmount] = useState<number | null>(null);
	const [receipt, setReceipt] = useState<File | null>(null);
	const [btcAmount, setBtcAmount] = useState<number>(0);

	const convertUsd = async () => {
		await convert.ready(); //Wait for the initial cache to load

		if (!usdAmount) {
			setBtcAmount(0);
		} else {
			setBtcAmount(convert.USD.ETH(usdAmount) || 0);
		}
	};

	useEffect(() => {
		convertUsd();
	}, [usdAmount]);

	const handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		if (e.target.files) {
			const file = e.target.files[0];

			if (!file) {
				setReceipt(null);
				return;
			}

			if (file.size > MAX_FILE_SIZE) {
				console.log('Too Large');
				return;
			}

			setReceipt(e.target.files[0]);
		}
	};

	const walletAddressApiRequest = useApi<
		{ status: boolean; data: string },
		{ tokens: BvTokens; currency: 'btc' | 'eth' }
	>(get_wallet_address);
	const submitDepositApiRequest = useApi<
		{ status: boolean; message: string },
		{ tokens: BvTokens; data: DepositInput }
	>(submit_deposit);

	const fetchWalletAddressForDeposit = async () => {
		if (!tokens) return;

		walletAddressApiRequest.reset();

		try {
			const data = await walletAddressApiRequest.request({
				tokens,
				currency: 'eth',
			});

			setStep(2);
			setWalletAddress(data?.data || '');
		} catch (error) {
			console.log(error);
		}
	};

	const { toggleSuccessAlert } = useScreenActions();

	const submitDepositRequest = async () => {
		if (!tokens || !usdAmount || !receipt) return;

		try {
			const payload = {
				usd_amount: usdAmount,
				receipt,
			};

			const data = await submitDepositApiRequest.request({
				tokens,
				data: payload,
			});

			if (data?.status) {
				onClose();
				toggleSuccessAlert(data.message);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();

		if (walletAddressApiRequest.loading || submitDepositApiRequest.loading)
			return;

		if (step === 1) {
			fetchWalletAddressForDeposit();
		} else if (step === 2) {
			submitDepositRequest();
		}
	};

	const copyWalletAddress = () => {
		navigator.clipboard.writeText(walletAddress);
		toast('Wallet address copied!', { theme: 'colored', type: 'info' });
	};

	return (
		<PopModal modalToggler={isOpen} onClose={() => {}}>
			<div className='bv-btc-deposit-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h4 className='modal-title'>
								Deposit via <strong>Ethereum</strong>
							</h4>
							<button type='button' className='close' onClick={onClose}>
								×
							</button>
						</div>

						<form autoComplete='off' onSubmit={handleSubmit}>
							<div className='modal-body'>
								{step === 1 && (
									<>
										<label className='col-md-12 modal-msg-heading'>
											<strong>DEPOSIT AMOUNT</strong>
											<h4 className='conversion1'>{btcAmount} ETH</h4>
										</label>

										<hr />

										<div className='form-group'>
											<div className='input-group'>
												<div className='input-group'>
													<input
														type='number'
														name='amount'
														className='form-control input-lg'
														id='amount'
														value={usdAmount || ''}
														onChange={(e) =>
															setUsdAmount(e.target.valueAsNumber)
														}
														placeholder=' Enter Amount'
														required
													/>
													<div className='input-group-prepend'>
														<span className='input-group-text'>USD</span>
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{step === 2 && (
									<>
										<span>
											Send {btcAmount} ETH To wallet address below and upload
											receipt for confirmation.
										</span>

										<br />

										<strong
											className='wallet_address'
											onClick={copyWalletAddress}
										>
											{walletAddress}
										</strong>

										<div className='mt-3 mb-3'>
											<label
												className='form-label text-light'
												htmlFor='receiptFile'
											>
												{receipt && (
													<img
														style={{
															maxHeight: 200,
															objectFit: 'cover',
															width: '100%',
														}}
														src={URL.createObjectURL(receipt)}
													/>
												)}
											</label>
											<input
												className='form-control'
												style={{ padding: 0, height: 40 }}
												name='id_file'
												id='receiptFile'
												type='file'
												accept='image/*'
												required
												onChange={handleFileSelected}
											/>
										</div>
									</>
								)}
							</div>

							{walletAddressApiRequest.error && (
								<div className='mt-2 mb-2 api-error alert alert-danger text-center'>
									{walletAddressApiRequest.error}
								</div>
							)}

							{submitDepositApiRequest.error && (
								<div className='mt-2 mb-2 api-error alert alert-danger text-center'>
									{submitDepositApiRequest.error}
								</div>
							)}

							<div className='modal-footer'>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={
										walletAddressApiRequest.loading ||
										submitDepositApiRequest.loading
									}
								>
									{step === 1 ? 'Preview' : 'Request'}
								</button>

								<button
									type='button'
									className='btn btn-default'
									onClick={onClose}
								>
									Close{' '}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</PopModal>
	);
};

export default EthDeposit;
