import axios from 'axios';
import { API_URL } from '../config/env';
import { ContactUsInput } from '../interfaces/others';

class PublicService {
	submit_contact_form = (data: ContactUsInput) => {
		return axios.post(`${API_URL}/public/contact`, data);
	};
}

const publicService = new PublicService();
export default publicService;
