import React from 'react';
import { Withdraw } from '../../../interfaces/Withdraw';

interface WithdrawHistoryMobileProps {
	withdraws: Withdraw[];
}

const WithdrawHistoryMobile: React.FC<WithdrawHistoryMobileProps> = ({
	withdraws,
}) => {
	return (
		<div className='bv-withdraw-history-mobile'>
			<div className='mobile-table'>
				<div className='container'>
					<div className='element-heading mt-3'>
						<h6>Withdraw Log</h6>
					</div>
				</div>

				<div className='container responsive-table'>
					<div className='card'>
						<div className='card-body'>
							<table className='table mb-0'>
								<thead>
									<tr>
										<td>Transaction ID</td>
										<td>Wallet</td>
										<td>Amount</td>
										<td>Status</td>
									</tr>
								</thead>

								<tbody>
									{withdraws.length === 0 && (
										<tr>
											<td colSpan={5}>
												{' '}
												You don't have any withdraw history !!
											</td>
										</tr>
									)}

									{withdraws.map((item) => (
										<tr key={item.withdraw_id}>
											<td>{item.withdraw_id}</td>
											<td>{item.wallet}</td>
											<td>{item.amount}</td>
											<td>{item.status}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithdrawHistoryMobile;
