import React, { useEffect, useRef } from 'react';
import { useForm } from '../../../hooks/useForm';
import { UserIdentificationInput } from '../../../interfaces/Auth';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import authService from '../../../services/AuthService';
import useApi from '../../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { useAuthActions } from '../../../hooks/useReduxActions';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const save_identification = (data: UserIdentificationInput) =>
	authService.save_identification(data);

export const UserIdentification: React.FC = () => {
	const navigate = useNavigate();

	const sessionRef = useRef<string | null>(null);

	const identificationForm = useForm<UserIdentificationInput>(
		{
			method: '',
			id_file: null,
		},
		{
			method: emptyValidator,
		}
	);

	const handleChange: React.ChangeEventHandler<
		HTMLSelectElement | HTMLTextAreaElement
	> = (e) => {
		identificationForm.onChange(
			e.target.name as keyof UserIdentificationInput,
			e.target.value
		);
	};

	const identificationApiRequest = useApi<
		{ status: boolean },
		UserIdentificationInput
	>(save_identification);

	const handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		if (e.target.files) {
			const file = e.target.files[0];

			if (file.size > MAX_FILE_SIZE) {
				console.log('Too Large');
				return;
			}

			identificationForm.onChange('id_file', e.target.files[0]);
		}
	};

	const { toggleSuccessAlert } = useAuthActions();

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		identificationForm.resetFormErrors();
		identificationApiRequest.reset();

		const valid = identificationForm.validate();

		console.log(valid);

		if (valid && sessionRef.current) {
			const result = await identificationApiRequest.request({
				...identificationForm.form,
				session: sessionRef.current,
			});

			if (result) {
				toggleSuccessAlert('Government ID Submitted Successfully!');
				navigate(AllRouteConstants.auth.login);
			}
		}
	};

	useEffect(() => {
		const search = new URLSearchParams(window.location.search);

		const session = search.get('session');

		if (!session) navigate(AllRouteConstants.auth.login);
		else sessionRef.current = session;
	}, []);

	return (
		<div className='bv-user-identification-page'>
			<div className='container my-auto py-5'>
				<div className='row g-0'>
					<div className='col-11 col-md-8 col-lg-7 col-xl-6 mx-auto'>
						<h3 className='text-white mb-4'>Identity Verfication</h3>

						<div className='d-flex align-items-center my-4'>
							<hr className='flex-grow-1 bg-dark-4' />
						</div>

						<form className='form-dark' onSubmit={handleSubmit}>
							<div className='mb-3'>
								<label className='form-label text-light' htmlFor='emailAddress'>
									Method of Verification
								</label>

								<select
									name='method'
									className='form-control'
									required
									value={identificationForm.form.method}
									onChange={handleChange}
								>
									<option disabled selected value=''>
										Select Method of Verification
									</option>
									<option value='drivers_license'> Drivers License </option>
									<option value='international_passport'>
										{' '}
										International Passport{' '}
									</option>
									<option value='national_id'> National ID </option>
									<option value='others'> Other </option>
								</select>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light' htmlFor='idFile'>
									Upload ID
								</label>
								<input
									className='form-control'
									style={{ padding: 0, height: 40 }}
									name='id_file'
									id='idFile'
									type='file'
									accept='image/*'
									required
									onChange={handleFileSelected}
								/>
							</div>

							<button className='btn btn-primary my-2' type='submit'>
								Upload Verification
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
