import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { TradeActionTypes } from './action-types';
import { Trade, TradeMode } from '../../interfaces/trading';

export const setTradeMode =
	(data: TradeMode) => (dispatch: Dispatch<TradeActionTypes>) => {
		dispatch({ type: ActionConstants.SET_TRADE_MODE, payload: data });
	};

export const addTrade =
	(data: Trade) => (dispatch: Dispatch<TradeActionTypes>) => {
		dispatch({ type: ActionConstants.ADD_TRADE, payload: data });
	};

export const setTrades =
	(data: Trade[]) => (dispatch: Dispatch<TradeActionTypes>) => {
		dispatch({ type: ActionConstants.SET_TRADES, payload: data });
	};
