import produce from 'immer';
import { ActionConstants } from './action-constants';
import { ScreenActionTypes } from './action-types';
import { ProfileVerifyData } from '../../interfaces/Profile';

interface initialStateInterface {
	verifyData: ProfileVerifyData | null;
}

const initialState = {
	verifyData: null,
} as initialStateInterface;

export const profileReducer = (
	state: initialStateInterface = initialState,
	Action: ScreenActionTypes
): initialStateInterface => {
	return produce(state, (profile_state) => {
		switch (Action.type) {
			case ActionConstants.SET_PROFILE_VERIFY_DATA:
				profile_state.verifyData = Action.payload || null;
				return profile_state;

			default:
				return profile_state;
		}
	});
};
