import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import PopModal from '../../../components/ModelLayout/ModalLayout';
import { PopMenuItem } from '../../../interfaces/others';

interface MenuModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	data: { title: string; options: PopMenuItem[] };
}

const MenuModal: React.FC<MenuModalProps> = ({ isOpen, onClose, data }) => {
	const { title, options } = data;

	const navigate = useNavigate();

	const handleClick = (path: string) => () => {
		onClose();
		navigate(path);
	};

	return (
		<PopModal modalToggler={isOpen} onClose={onClose}>
			<div className='bv-trading-menu-modal'>
				<h2>{title}</h2>
				<a className='close2' onClick={onClose}>
					×
				</a>

				<div className='content'>
					<nav className='kr-manager-nav2'>
						<ul>
							{options.map((item, idx) => (
								<li
									key={`menu-item-${item.title}-${idx}`}
									kr-module='manager'
									kr-view='statistics'
								>
									<a className='no-link' onClick={handleClick(item.path)}>
										<img src={item.imgSrc} />
										&nbsp;
										<br />
										{item.title}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</PopModal>
	);
};

export default MenuModal;
