import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import useApi from "../../../hooks/useApi";
import authService from "../../../services/AuthService";
import SuccessModal from "../../../components/SuccessModal";
import { toast } from "react-toastify";

const confirm = (hash: string) => authService.confirm_email(hash);

export const VerifyEmail: React.FC = () => {
    const navigate = useNavigate();

    const [successAlert, setSuccessAlert] = useState<string | null>(null);

    const closeSuccessAlert = () => {
        setSuccessAlert(null);
        navigate(AllRouteConstants.main.index);
    }

    const onError = () => {
        if (!verifyEmailApiRequest.error) return;
        
        toast(verifyEmailApiRequest.error, { theme: "colored", type: "error" });

        toast.onChange((item) => {
            if (!item.isLoading) {
                navigate(AllRouteConstants.main.index);
            }
        });  
    }

    const verifyEmailApiRequest = useApi<{status: boolean, message: string}, string>(confirm);

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);

        const hash = search.get("hash");

        console.log(hash);

        if (!hash) {
            navigate(AllRouteConstants.main.index);
        } else {
            verifyEmailApiRequest.request(hash)
                .then((data) => {
                    console.log(data);
                    if (data && data.status) {
                        setSuccessAlert(data.message);
                    } else {
                        onError();
                    }
                })
        }
    }, []);

    useEffect(() => {
        if (verifyEmailApiRequest.error) {
            onError();
        }
    }, [verifyEmailApiRequest.error]);


    return (
        <>
            {successAlert && (
					<SuccessModal
						handleOk={closeSuccessAlert}
						open={successAlert !== null}
						close={closeSuccessAlert}
						text={successAlert}
					/>
				)}

            <div className="bv-verify-email-page">
                <span>Loading...</span>
            </div>
        </>
    )
}