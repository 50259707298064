import React from 'react';
import { Route } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import {
	CompleteProfile,
	ForgotPassword,
	Login,
	Register,
	ResetPassword,
	UserIdentification,
} from '../pages';

const AuthRoutes = () => {
	return (
		<>
			<Route path={AllRouteConstants.auth.signup} element={<Register />} />
			<Route path={AllRouteConstants.auth.login} element={<Login />} />
			<Route
				path={AllRouteConstants.auth.forgot_password}
				element={<ForgotPassword />}
			/>
			<Route
				path={AllRouteConstants.auth.complete_profile}
				element={<CompleteProfile />}
			/>
			<Route
				path={AllRouteConstants.auth.user_identification}
				element={<UserIdentification />}
			/>
			<Route
				path={AllRouteConstants.auth.reset_password}
				element={<ResetPassword />}
			/>
		</>
	);
};

export default AuthRoutes;
