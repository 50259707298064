import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthActions } from '../../../hooks/useReduxActions';
import { useForm } from '../../../hooks/useForm';
import { BvTokens, LoginInput } from '../../../interfaces/Auth';
import { emailValidator } from '../../../utils/validators/emailValidator';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import useApi from '../../../hooks/useApi';
import { User } from '../../../interfaces/User';
import authService from '../../../services/AuthService';

const login = (data: LoginInput) => authService.login(data);

export const Login: React.FC = () => {
	const navigate = useNavigate();

	const { signIn } = useAuthActions();

	const loginForm = useForm<LoginInput>(
		{
			email: '',
			password: '',
		},
		{
			email: emailValidator,
			password: emptyValidator,
		}
	);

	const loginApiRequest = useApi<
		{ status: boolean; message: string; data: User; tokens: BvTokens },
		LoginInput
	>(login);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		loginForm.onChange(e.target.name as keyof LoginInput, e.target.value);
	};

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (loginApiRequest.loading) {
			return;
		}

		loginApiRequest.reset();
		loginForm.resetFormErrors();

		const valid = loginForm.validate();

		if (valid) {
			const result = await loginApiRequest.request(loginForm.form);

			if (result && result.status) {
				signIn({
					user: result.data,
					tokens: result.tokens,
				});
				navigate(AllRouteConstants.user.home);
			}
		}
	};

	return (
		<div className='bv-login-page'>
			<div className='container my-auto py-5'>
				<div className='row g-0'>
					<div className='col-11 col-md-8 col-lg-7 col-xl-6 mx-auto'>
						<p className='text-2 text-light'>
							Not a member?{' '}
							<a
								className='fw-500'
								onClick={() => navigate(AllRouteConstants.auth.signup)}
							>
								Register
							</a>
						</p>

						<h3 className='text-white mb-4'>Log In to Your Account</h3>

						<div className='d-flex'>
							<a
								className='btn btn-primary btn-sm fw-400 rounded-3 shadow-none'
								onClick={() => navigate(AllRouteConstants.main.index)}
							>
								<span className='me-2'>
									<FontAwesomeIcon
										icon={icon({ name: 'home', style: 'solid' })}
									/>
								</span>
								<span className='mx-3'>Back To Homepage</span>
							</a>
						</div>

						<div className='d-flex align-items-center my-4'>
							<hr className='flex-grow-1 bg-dark-4' />
						</div>

						<form
							name='loginForm'
							className='form-dark'
							autoComplete=''
							onSubmit={handleSubmit}
						>
							<div className='mb-3'>
								<label className='form-label text-light'>Email</label>
								<input
									type='text'
									className='form-control'
									name='email'
									value={loginForm.form.email}
									onChange={handleChange}
									required
									placeholder='Enter Your Email'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light loginPassword'>
									Password
								</label>

								<Link
									className='float-end text-2'
									to={AllRouteConstants.auth.forgot_password}
								>
									Forgot Password ?
								</Link>

								<input
									type='password'
									name='password'
									className='form-control'
									id='loginPassword'
									required
									value={loginForm.form.password}
									onChange={handleChange}
									placeholder='Enter Password'
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light loginPassword'>
									Remember me
								</label>{' '}
								<input type='checkbox' id='cb1' name='check' />
							</div>

							{loginApiRequest.error && (
								<div className='mt-3 api-error alert alert-danger text-center'>
									{loginApiRequest.error}
								</div>
							)}

							<button
								className='btn btn-primary my-2'
								name='submit'
								type='submit'
								disabled={loginApiRequest.loading}
							>
								Login
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
