import produce from 'immer';
import { ActionConstants } from './action-constants';
import { WithdrawActionTypes } from './action-types';
import { Withdraw } from '../../interfaces/Withdraw';

interface initialStateInterface {
	withdraws: Withdraw[];
}

const initialState = {
	withdraws: [],
} as initialStateInterface;

export const withdrawReducer = (
	state: initialStateInterface = initialState,
	Action: WithdrawActionTypes
): initialStateInterface => {
	return produce(state, (withdraw_state) => {
		switch (Action.type) {
			case ActionConstants.SET_WITHDRAWS:
				withdraw_state.withdraws = Action.payload || [];
				return withdraw_state;

			case ActionConstants.ADD_WITHDRAW:
				withdraw_state.withdraws = [
					Action.payload,
					...withdraw_state.withdraws,
				];
				return withdraw_state;

			default:
				return withdraw_state;
		}
	});
};
