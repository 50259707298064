import React, { useEffect } from 'react';
import './styles.scss';
import PopModal from '../../../../components/ModelLayout/ModalLayout';
import { useForm } from '../../../../hooks/useForm';
import { CreditCardDepoistInput } from '../../../../interfaces/Deposit';
import { emptyValidator } from '../../../../utils/validators/emptyValidator';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { toast } from 'react-toastify';
import { BvTokens } from '../../../../interfaces/Auth';
import depositService from '../../../../services/DepositService';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useApi from '../../../../hooks/useApi';

const MONTHS = [
	{
		label: 'January',
		value: '1',
	},
	{
		label: 'February',
		value: '2',
	},
	{
		label: 'March',
		value: '3',
	},
	{
		label: 'April',
		value: '4',
	},
	{
		label: 'May',
		value: '5',
	},
	{
		label: 'June',
		value: '6',
	},
	{
		label: 'July',
		value: '7',
	},
	{
		label: 'August',
		value: '8',
	},
	{
		label: 'September',
		value: '9',
	},
	{
		label: 'October',
		value: '10',
	},
	{
		label: 'November',
		value: '11',
	},
	{
		label: 'December',
		value: '12',
	},
];

interface CardDepositProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

const submit_deposit = (data: {
	tokens: BvTokens;
	data: CreditCardDepoistInput;
}) => depositService.submit_deposit_card(data.tokens, data.data);

const CardDeposit: React.FC<CardDepositProps> = ({ isOpen, onClose }) => {
	const { tokens } = useAppSelector((state) => state.authReducer);

	const submitDepositApiRequest = useApi<
		{ status: boolean; message: string },
		{ tokens: BvTokens; data: CreditCardDepoistInput }
	>(submit_deposit);

	const form = useForm<CreditCardDepoistInput>(
		{
			usd_amount: '',
			card_number: '',
			cvv: '',
			holder_name: '',
			month: '',
			year: '',
			zip_code: '',
		},
		{
			card_number: emptyValidator,
			cvv: emptyValidator,
			holder_name: emptyValidator,
			month: emptyValidator,
			year: emptyValidator,
			usd_amount: emptyValidator,
			zip_code: emptyValidator,
		}
	);

	const getValidYears = () => {
		const today = new Date();
		const array = [];

		let counter = 1;

		while (counter <= 15) {
			array.push({
				label: today.getFullYear().toString(),
				value: today.getFullYear().toString(),
			});
			today.setFullYear(today.getFullYear() + 1);
			counter++;
		}

		return array;
	};

	useEffect(() => {
		return form.reset;
	}, []);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (submitDepositApiRequest.loading) {
			return;
		}

		submitDepositApiRequest.reset();
		form.resetFormErrors();

		const valid = form.validate();

		if (isNaN(Number(form.form.card_number))) {
			toast('Inalid Card Number!', { type: 'error', theme: 'colored' });
			return;
		}

		if (valid && tokens) {
			const res = await submitDepositApiRequest
				.request({ tokens, data: form.form })
				.finally(form.reset);

			if (res?.status) {
				toast(res.message, { type: 'success', theme: 'colored' });
				onClose();
			}
		} else {
			if (!valid)
				toast('Please input all fields correctly!', {
					type: 'error',
					theme: 'colored',
				});
		}
	};

	return (
		<PopModal modalToggler={isOpen} onClose={() => {}}>
			<div className='card-deposit-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h4 className='modal-title'>
								Deposit via <strong>Card</strong>
							</h4>

							<button type='button' className='close' onClick={onClose}>
								×
							</button>
						</div>

						<form autoComplete='off' onSubmit={handleSubmit}>
							<div className='modal-body'>
								<div className='container'>
									<div className='row mb-2'>
										<div className='col'>
											<InputField<CreditCardDepoistInput>
												name='card_number'
												maxLength={12}
												type='number'
												error={form.formErrors.card_number}
												onChange={form.onChange}
												label='Card Number'
												placeholder='0000 0000 0000 0000'
												value={form.form.card_number}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<CreditCardDepoistInput>
												name='holder_name'
												error={form.formErrors.holder_name}
												onChange={form.onChange}
												label='Card Holder'
												placeholder='John Doe'
												value={form.form.holder_name}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col-7'>
											<label htmlFor='' className='form-label'>
												Expiration Date
											</label>

											<div className='row'>
												<div className='col-6'>
													<SelectField<CreditCardDepoistInput>
														error={form.formErrors.month}
														options={MONTHS}
														onChange={form.onChange}
														name='month'
														placeholder='Month'
														value={form.form.month.toString()}
													/>
												</div>
												<div className='col-6'>
													<SelectField<CreditCardDepoistInput>
														error={form.formErrors.year}
														options={getValidYears()}
														onChange={form.onChange}
														name='year'
														placeholder='Year'
														value={form.form.year.toString()}
													/>
												</div>
											</div>
										</div>

										<div className='col-5'>
											<InputField
												error={form.formErrors.cvv}
												type='number'
												label='CVV'
												name='cvv'
												value={form.form.cvv}
												onChange={form.onChange}
												placeholder='XXX'
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<CreditCardDepoistInput>
												name='usd_amount'
												type='number'
												error={form.formErrors.usd_amount}
												onChange={form.onChange}
												label='Amount'
												placeholder='1000'
												min={10}
												value={form.form.usd_amount.toString()}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<CreditCardDepoistInput>
												name='zip_code'
												type='text'
												error={form.formErrors.zip_code}
												onChange={form.onChange}
												label='Zip code'
												placeholder='DF656R'
												value={form.form.zip_code}
											/>
										</div>
									</div>
								</div>
							</div>

							{submitDepositApiRequest.error && (
								<div className='mt-2 mb-2 api-error alert alert-danger text-center'>
									{submitDepositApiRequest.error}
								</div>
							)}

							<div className='modal-footer'>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={submitDepositApiRequest.loading}
								>
									Submit
								</button>

								<button
									type='button'
									className='btn btn-default'
									onClick={onClose}
								>
									Close
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</PopModal>
	);
};

export default CardDeposit;
