import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { useForm } from '../../../hooks/useForm';
import { ResetPasswordInput } from '../../../interfaces/Auth';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import authService from '../../../services/AuthService';
import useApi from '../../../hooks/useApi';
import { toast } from 'react-toastify';
import { useAuthActions } from '../../../hooks/useReduxActions';

const get_details = (hash: string) =>
	authService.get_password_reset_link_details(hash);
const confirm_change = (data: Omit<ResetPasswordInput, 'email'>) =>
	authService.confirm_password_reset(data);

export const ResetPassword: React.FC = () => {
	const navigate = useNavigate();

	const hashRef = useRef<string>('');

	const detailsApiRequest = useApi<{ status: boolean; data: string }, string>(
		get_details
	);
	const confirmApiRequest = useApi<
		{ status: boolean; message: string },
		Omit<ResetPasswordInput, 'email'>
	>(confirm_change);

	const resetPasswordForm = useForm<ResetPasswordInput>(
		{
			password: '',
			confirmPassword: '',
		},
		{
			password: emptyValidator,
			confirmPassword: emptyValidator,
		}
	);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		resetPasswordForm.onChange(
			e.target.name as keyof ResetPasswordInput,
			e.target.value
		);
	};

	useEffect(() => {
		if (detailsApiRequest.error) {
			toast(detailsApiRequest.error, { type: 'error', theme: 'colored' });
		}
	}, [detailsApiRequest.error]);

	useEffect(() => {
		if (confirmApiRequest.error) {
			toast(confirmApiRequest.error, { type: 'error', theme: 'colored' });
		}
	}, [confirmApiRequest.error]);

	useEffect(() => {
		const search = new URLSearchParams(window.location.search);

		const hash = search.get('hash');

		if (!hash) {
			navigate(AllRouteConstants.auth.login);
		} else {
			hashRef.current = hash;
			detailsApiRequest
				.request(hash)
				.then((result) => {
					resetPasswordForm.onChange('email', result?.data || '');
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, []);

	const { toggleSuccessAlert } = useAuthActions();

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (confirmApiRequest.loading) {
			return;
		}

		resetPasswordForm.resetFormErrors();
		confirmApiRequest.reset();

		if (resetPasswordForm.validate() && hashRef.current) {
			const result = await confirmApiRequest.request({
				...resetPasswordForm.form,
				hash: hashRef.current,
			});

			if (result && result.status) {
				toggleSuccessAlert(result.message);

				resetPasswordForm.reset();

				navigate(AllRouteConstants.auth.login);
			}
		}
	};

	return (
		<div className='bv-reset-password-page'>
			<div className='container my-auto py-5'>
				<div className='row g-0'>
					<div className='col-11 col-md-8 col-lg-7 col-xl-6 mx-auto'>
						<h3 className='text-white mb-4'>Password Reset</h3>

						<div className='d-flex align-items-center my-4'>
							<hr className='flex-grow-1 bg-dark-4' />
						</div>

						<form className='form-dark' onSubmit={handleSubmit}>
							<div className='mb-3'>
								<label className='form-label text-light' htmlFor='emailAddress'>
									E-mail
								</label>
								<input
									className='form-control'
									type='email'
									name='email'
									id='email'
									value={resetPasswordForm.form.email}
									placeholder='Enter Your E-mail Address'
									readOnly
								/>
							</div>

							<div className='mb-3'>
								<label className='form-label text-light' htmlFor='password'>
									New Password
								</label>
								<input
									type='password'
									name='password'
									className='form-control'
									id='password'
									placeholder='Enter Password'
									value={resetPasswordForm.form.password}
									onChange={handleChange}
								/>
							</div>

							<div className='mb-3'>
								<label
									className='form-label text-light'
									htmlFor='confirmPassword'
								>
									Confirm Password
								</label>
								<input
									type='password'
									name='confirmPassword'
									className='form-control'
									id='confirmPassword'
									placeholder='Confirm Password'
									value={resetPasswordForm.form.confirmPassword}
									onChange={handleChange}
								/>
							</div>

							<button
								className='btn btn-primary my-2'
								type='submit'
								disabled={confirmApiRequest.loading}
							>
								Change Password&lt;
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
