import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './styles.scss';
import { NoRequireAuth } from '../../HoC/NoRequireAuth';
import { AllRouteConstants } from '../../router/routes';
import logo from '../../assets/images/reed.png';
import SuccessModal from '../../components/SuccessModal';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAuthActions } from '../../hooks/useReduxActions';

const AuthLayout: React.FC = () => {
	const { successAlert } = useAppSelector((state) => state.authReducer);

	const { toggleSuccessAlert } = useAuthActions();

	useEffect(() => {
		return () => {
			toggleSuccessAlert();
		};
	}, []);

	return (
		<NoRequireAuth>
			<>
				{successAlert && (
					<SuccessModal
						handleOk={toggleSuccessAlert}
						open={successAlert !== null}
						close={toggleSuccessAlert}
						text={successAlert}
					/>
				)}

				<div className='bv-auth-layout'>
					<a onClick={() => {}} className='bv-auth-layout__feedback-button'>
						Live Chat
					</a>

					<div className='container-fluid px-0'>
						<div className='row g-0 min-vh-100'>
							<div className='d-none d-md-block col-md-4'>
								<div className='hero-wrap d-flex align-items-center h-100'>
									<div className='hero-mask opacity-5 bg-dark'></div>
									<div className='hero-content mx-auto w-100 h-100'>
										<div className='container d-flex flex-column h-100'>
											<div className='row g-0'>
												<div className='col-11 col-lg-9 mx-auto'>
													<div className='logo mt-5 mb-5'>
														{' '}
														<Link
															className='d-flex'
															to={AllRouteConstants.main.index}
															title=''
														>
															<img src={logo} alt='' />
														</Link>{' '}
													</div>
												</div>
											</div>
											<div className='row g-0 mt-3'>
												<div className='col-11 col-lg-9 mx-auto'></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-md-8 d-flex flex-column align-items-center bg-dark'>
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</>
		</NoRequireAuth>
	);
};

export default AuthLayout;
