import produce from 'immer';
import { ActionConstants } from './action-constants';
import { DepositActionTypes } from './action-types';
import { Deposit } from '../../interfaces/Deposit';

interface initialStateInterface {
	deposits: Deposit[];
}

const initialState = {
	deposits: [],
} as initialStateInterface;

export const depositReducer = (
	state: initialStateInterface = initialState,
	Action: DepositActionTypes
): initialStateInterface => {
	return produce(state, (deposit_state) => {
		switch (Action.type) {
			case ActionConstants.SET_DEPOSITS:
				deposit_state.deposits = Action.payload || [];
				return deposit_state;

			case ActionConstants.ADD_DEPOSIT:
				deposit_state.deposits = [Action.payload, ...deposit_state.deposits];
				return deposit_state;

			default:
				return deposit_state;
		}
	});
};
