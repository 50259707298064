import React, { useEffect } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrManagerNav from '../../../components/KrManagerNav';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { BvTokens } from '../../../interfaces/Auth';
import profitService from '../../../services/ProfitService';
import useApi from '../../../hooks/useApi';
import { Profit as ProfitObj } from '../../../interfaces/Profit';
import { useProfitActions } from '../../../hooks/useReduxActions';

const get_profits = (tokens: BvTokens) => profitService.get_profits(tokens);

export const Profit: React.FC = () => {
	const { tokens } = useAppSelector((state) => state.authReducer);
	const { profits, initialized } = useAppSelector(
		(state) => state.profitReducer
	);

	const getProfitsApiRequest = useApi<
		{ status: boolean; data: ProfitObj[] },
		BvTokens
	>(get_profits);

	const { setProfits } = useProfitActions();

	useEffect(() => {
		if (!initialized && tokens) {
			getProfitsApiRequest.request(tokens).then((data) => {
				if (data && data.status) {
					setProfits(data.data);
				}
			});
		}
	}, [initialized, tokens]);

	return (
		<div className='bv-profit-page'>
			<KrLeftSide />

			<div className='w-full'>
				<KrManagerNav />

				<div className='kr-manager-filter'>
					<button
						type='button'
						className='btn btn-autowidth btn-red btn-large'
						name='button'
					>
						Your Earnings From Trade
					</button>
				</div>

				<div className='kr-admin-table'>
					<table>
						<thead>
							<tr>
								<td>S/N</td>
								<td>Trade ID</td>
								<td>Amount</td>
								<td>Profits</td>
								<td>Earnings</td>
								<td>Date Earned</td>
							</tr>
						</thead>
						<tbody>
							{profits.length === 0 && (
								<tr>
									<td colSpan={5}> You have not Earned Yet</td>
								</tr>
							)}

							{profits.map((profit, idx) => (
								<tr key={profit.profit_id}>
									<td>{idx + 1}</td>
									<td>{profit.profit_id}</td>
									<td>{profit.amount}</td>
									<td>{profit.profit}</td>
									<td>{profit.earnings}</td>
									<td>
										{new Date(profit.createdAt).toISOString().split('T')[0]}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className='mobile-table'>
					<div className='container'>
						<div className='element-heading mt-3'>
							<h6>Your Earnings</h6>
						</div>
					</div>

					<div className='container'>
						<div className='card'>
							<div className='card-body'>
								<table className='table mb-0'>
									<thead>
										<tr>
											<th scope='col'>Amount</th>
											<th scope='col'>Profits</th>
											<th scope='col'>Earnings</th>
										</tr>
									</thead>
									<tbody>
										{profits.length === 0 && (
											<tr>
												<td colSpan={5}> You have not Earned Yet</td>
											</tr>
										)}

										{profits.map((profit) => (
											<tr key={profit.profit_id}>
												<td>{profit.amount}</td>
												<td>{profit.profit}</td>
												<td>{profit.earnings}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
