import produce from 'immer';
import { ActionConstants } from './action-constants';
import { ProfitActionTypes } from './action-types';
import { Profit } from '../../interfaces/Profit';

interface initialStateInterface {
	profits: Profit[];
	initialized: boolean;
}

const initialState = {
	profits: [],
	initialized: false,
} as initialStateInterface;

export const profitReducer = (
	state: initialStateInterface = initialState,
	Action: ProfitActionTypes
): initialStateInterface => {
	return produce(state, (profit_state) => {
		switch (Action.type) {
			case ActionConstants.SET_PROFITS:
				profit_state.profits = Action.payload || [];
				profit_state.initialized = true;
				return profit_state;

			default:
				return profit_state;
		}
	});
};
