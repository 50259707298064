import axios from 'axios';
import { API_URL } from '../config/env';
import { BvTokens } from '../interfaces/Auth';

class ProfileService {
	get_user_verification = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/profile/user-verification`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const profileService = new ProfileService();
export default profileService;
