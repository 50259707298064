import PopModal from '../ModelLayout/ModalLayout';
import './styles.scss';

interface SuccessModalProps {
	open: boolean;
	close: VoidFunction;
	handleOk: VoidFunction;
	text: string;
}

const SuccessModal: React.FC<SuccessModalProps> = (props) => {
	const { open, text, close, handleOk } = props;

	return (
		<PopModal modalToggler={open} onClose={close}>
			<div className='bv-success-modal'>
				<div className='sweet-alert'>
					<div className='sa-icon sa-success animate'>
						<span className='sa-line sa-tip animateSuccessTip'></span>
						<span className='sa-line sa-long animateSuccessLong'></span>
						<div className='sa-placeholder'></div>
						<div className='sa-fix'></div>
					</div>

					<h2>Success!</h2>

					<p style={{ display: 'block' }}>{text}</p>

					<div className='sa-button-container'>
						<div className='sa-confirm-button-container'>
							<button className='confirm' onClick={handleOk}>
								OK
							</button>
						</div>
					</div>
				</div>
			</div>
		</PopModal>
	);
};

export default SuccessModal;
