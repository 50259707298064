interface SelectFieldProps<T> {
	label?: string;
	value?: string;
	placeholder?: string;
	onChange: (name: keyof T, value: string | number) => void;
	options: { label: string; value: string }[];
	name: string;
	error: string | null;
}

const SelectField = <T,>(props: SelectFieldProps<T>) => {
	const {
		value = '',
		onChange,
		name,
		error,
		label,
		options,
		placeholder,
	} = props;

	const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
		onChange(e.target.name as keyof T, e.target.value);
	};

	return (
		<>
			{label && (
				<label htmlFor={name + '-id'} className='form-label'>
					{label}
				</label>
			)}

			<select
				id={name + '-id'}
				className='form-control'
				name={name}
				value={value}
				onChange={handleChange}
			>
				<option value='' disabled selected>
					{placeholder || 'Select'}
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>

			{error && (
				<div className='input-error mt-1'>
					{(name.substring(0, 1).toUpperCase() + name.substring(1)).replace(
						'_',
						' '
					) +
						' ' +
						error}
				</div>
			)}
		</>
	);
};

export default SelectField;
