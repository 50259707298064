import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllRouteConstants } from '../router/routes';
import { useAppSelector } from '../hooks/useAppSelector';

export interface NoRequireAuthProps {
	children: ReactElement;
}

export const NoRequireAuth: React.FC<NoRequireAuthProps> = ({ children }) => {
	const { user_data, tokens } = useAppSelector((state) => state.authReducer);

	const navigate = useNavigate();

	useEffect(() => {
		if (user_data && tokens) {
			navigate(AllRouteConstants.user.home);
		}
	}, [user_data, tokens]);

	return children;
};
