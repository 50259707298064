import React from 'react';
import btcImg from '../../../assets/images/btc.jpg';
import ethImg from '../../../assets/images/ethImg.jpeg';
import creditCardImg from '../../../assets/images/creditCard.jpeg';

interface DepositFundMobileProps {
	openModal: VoidFunction;
	openCardModal: VoidFunction;
	openEthModal: VoidFunction;
}

const DepositFundMobile: React.FC<DepositFundMobileProps> = ({
	openModal,
	openEthModal,
	openCardModal,
}) => {
	return (
		<div className='bv-deposit-fund-mobile py-3'>
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						<center>
							<h2> Select Payment Gateway </h2>

							<a className='btn btn-dark mb-4' onClick={openCardModal}>
								<img src={creditCardImg} alt='Payment Gateway' />
							</a>

							<a className='btn btn-dark mb-4' onClick={openModal}>
								<img src={btcImg} alt='Payment Gateway' />
							</a>

							<a className='btn btn-dark' onClick={openEthModal}>
								<img src={ethImg} alt='Payment Gateway' />
							</a>
						</center>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepositFundMobile;
