import React, { useState } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrManagerNav from '../../../components/KrManagerNav';
import WithdrawRequest from '../components/WithdrawRequest';
import WithdrawFundMobile from '../components/WithdrawFundMobile';
import btcImg from '../../../assets/images/btc.jpg';
import bankImg from '../../../assets/images/bank.png';
import BankWithdrawModal from '../components/BankWithdraw';

export const WithdrawFund: React.FC = () => {
	const [isBtcDepositOpen, setIsBtcDepositOpen] = useState(false);
	const [isBankWithdrawOpen, setBankWithdrawOpen] = useState(false);

	return (
		<>
			<WithdrawRequest
				isOpen={isBtcDepositOpen}
				onClose={() => setIsBtcDepositOpen(false)}
			/>

			<BankWithdrawModal
				isOpen={isBankWithdrawOpen}
				onClose={() => setBankWithdrawOpen(false)}
			/>

			<div className='bv-withdraw-fund-page'>
				<KrLeftSide />

				<section className='kr-dashboard'>
					<KrManagerNav />

					<div className='kr-manager-filter'>
						<button
							type='button'
							className='btn btn-autowidth btn-red btn-large'
							name='button'
						>
							Withdraw Fund
						</button>
					</div>

					<div className='kr-admin-table'>
						<div className='kr-admin-line kr-admin-line-cls'>
							<div className='kr-admin-field'>
								<div>
									<label>Select payment method</label>
								</div>
								<div></div>
							</div>

							<section className='mb-5'>
								<div className='payment-block mb-3 p-4 bg-light'>
									<div>
										<a className='ab' onClick={() => setBankWithdrawOpen(true)}>
											<label
												className='form-check-label'
												htmlFor='exampleRadio1'
											>
												<img style={{ height: 70 }} src={bankImg} alt='p' />
											</label>
										</a>
									</div>
								</div>

								<div className='payment-block mb-3 p-4 bg-light'>
									<div>
										<a className='ab' onClick={() => setIsBtcDepositOpen(true)}>
											<label
												className='form-check-label'
												htmlFor='exampleRadio1'
											>
												<img style={{ height: 30 }} src={btcImg} alt='p' />
											</label>
										</a>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>

			<WithdrawFundMobile
				openModal={() => setIsBtcDepositOpen(true)}
				openBankModal={() => setBankWithdrawOpen(true)}
			/>
		</>
	);
};
