import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { WithdrawActionTypes } from './action-types';
import { Withdraw } from '../../interfaces/Withdraw';

export const setWithdraws =
	(data: Withdraw[]) => (dispatch: Dispatch<WithdrawActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_WITHDRAWS,
			payload: data,
		});
	};

export const addWithdraw =
	(data: Withdraw) => (dispatch: Dispatch<WithdrawActionTypes>) => {
		dispatch({
			type: ActionConstants.ADD_WITHDRAW,
			payload: data,
		});
	};
