import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { CachedActionTypes } from './action-types';
import { TradeMode } from '../../interfaces/trading';

export const toggleTradeMode =
	() => (dispatch: Dispatch<CachedActionTypes>) => {
		dispatch({ type: ActionConstants.CHANGE_TRADE_MODE });
	};

export const setTradeMode =
	(mode: TradeMode) => (dispatch: Dispatch<CachedActionTypes>) => {
		dispatch({ type: ActionConstants.SET_TRADE_MODE, payload: mode });
	};
