import axios from 'axios';
import { BvTokens } from '../interfaces/Auth';
import { API_URL } from '../config/env';
import { TradeInput, TradeMode } from '../interfaces/trading';

class TradingService {
	submit_trading = (tokens: BvTokens, data: TradeInput) => {
		return axios.post(`${API_URL}/trading/submit`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	get_user_trades = (tokens: BvTokens, mode: TradeMode) => {
		return axios.get(`${API_URL}/trading?mode=${mode}`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const tradingService = new TradingService();
export default tradingService;
