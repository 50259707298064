import axios from 'axios';
import { API_URL } from '../config/env';
import { BvTokens } from '../interfaces/Auth';
import { BankWithdraw, WithdrawInput } from '../interfaces/Withdraw';

class WithdrawService {
	submit_withdraw = (tokens: BvTokens, data: WithdrawInput) => {
		return axios.post(`${API_URL}/withdraw`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	bank_withdraw = (tokens: BvTokens, data: BankWithdraw) => {
		return axios.post(`${API_URL}/withdraw/bank`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	get_withdraw_history = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/withdraw`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const withdrawService = new WithdrawService();
export default withdrawService;
