import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { ProfitActionTypes } from './action-types';
import { Profit } from '../../interfaces/Profit';

export const setProfits =
	(data: Profit[]) => (dispatch: Dispatch<ProfitActionTypes>) => {
		dispatch({
			type: ActionConstants.SET_PROFITS,
			payload: data,
		});
	};
