import React, { useEffect } from 'react';
import './styles.scss';
import PopModal from '../../../../components/ModelLayout/ModalLayout';
import { useForm } from '../../../../hooks/useForm';
import { emptyValidator } from '../../../../utils/validators/emptyValidator';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { toast } from 'react-toastify';
import { BvTokens } from '../../../../interfaces/Auth';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useApi from '../../../../hooks/useApi';
import { BankWithdraw } from '../../../../interfaces/Withdraw';
import withdrawService from '../../../../services/WithdrawService';

interface BankWithdrawModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

const submit = (data: { tokens: BvTokens; data: BankWithdraw }) =>
	withdrawService.bank_withdraw(data.tokens, data.data);

const BankWithdrawModal: React.FC<BankWithdrawModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { tokens } = useAppSelector((state) => state.authReducer);

	const submitApiRequest = useApi<
		{ status: boolean; message: string },
		{ tokens: BvTokens; data: BankWithdraw }
	>(submit);

	const form = useForm<BankWithdraw>(
		{
			account_number: '',
			bank_name: '',
			routing_number: '',
			type: '',
			amount: '',
		},
		{
			account_number: emptyValidator,
			bank_name: emptyValidator,
			routing_number: emptyValidator,
			type: emptyValidator,
			amount: emptyValidator,
		}
	);

	useEffect(() => {
		return form.reset;
	}, []);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (submitApiRequest.loading) {
			return;
		}

		submitApiRequest.reset();
		form.resetFormErrors();

		const valid = form.validate();

		if (valid && tokens) {
			const res = await submitApiRequest
				.request({ tokens, data: form.form })
				.finally(form.reset);

			if (res?.status) {
				toast(res.message, { type: 'success', theme: 'colored' });
				onClose();
			}
		} else {
			if (!valid)
				toast('Please input all fields correctly!', {
					type: 'error',
					theme: 'colored',
				});
		}
	};

	return (
		<PopModal modalToggler={isOpen} onClose={() => {}}>
			<div className='card-deposit-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h4 className='modal-title'>
								Withdraw to <strong>Bank</strong>
							</h4>

							<button type='button' className='close' onClick={onClose}>
								×
							</button>
						</div>

						<form autoComplete='off' onSubmit={handleSubmit}>
							<div className='modal-body'>
								<div className='container'>
									<div className='row mb-2'>
										<div className='col'>
											<InputField<BankWithdraw>
												name='bank_name'
												error={form.formErrors.bank_name}
												onChange={form.onChange}
												label='Bank Name'
												value={form.form.bank_name.toString()}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<SelectField<BankWithdraw>
												name='type'
												error={form.formErrors.type}
												onChange={form.onChange}
												label='Account Type'
												placeholder='Select account type'
												options={[
													{ label: 'Checking', value: 'Checking' },
													{ label: 'Savings', value: 'Savings' },
												]}
												value={form.form.type}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<BankWithdraw>
												name='account_number'
												type='number'
												error={form.formErrors.account_number}
												onChange={form.onChange}
												label='Account Number'
												value={form.form.account_number.toString()}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<BankWithdraw>
												name='routing_number'
												type='number'
												error={form.formErrors.routing_number}
												onChange={form.onChange}
												label='Routing Number'
												value={form.form.routing_number.toString()}
											/>
										</div>
									</div>

									<div className='row mb-2'>
										<div className='col'>
											<InputField<BankWithdraw>
												name='amount'
												type='number'
												error={form.formErrors.amount}
												onChange={form.onChange}
												label='Amount'
												value={form.form.amount.toString()}
											/>
										</div>
									</div>
								</div>
							</div>

							{submitApiRequest.error && (
								<div className='mt-2 mb-2 api-error alert alert-danger text-center'>
									{submitApiRequest.error}
								</div>
							)}

							<div className='modal-footer'>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={submitApiRequest.loading}
								>
									Submit
								</button>

								<button
									type='button'
									className='btn btn-default'
									onClick={onClose}
								>
									Close
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</PopModal>
	);
};

export default BankWithdrawModal;
