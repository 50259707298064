import produce from 'immer';
import { ActionConstants } from './action-constants';
import { TradeActionTypes } from './action-types';
import { Trade, TradeMode } from '../../interfaces/trading';

interface initialStateInterface {
	mode: TradeMode;
	trades: Trade[];
	initialized: boolean;
}

const initialState: initialStateInterface = {
	mode: 'demo',
	trades: [],
	initialized: false,
};

export const tradingReducer = (
	state: initialStateInterface = initialState,
	Action: TradeActionTypes
): initialStateInterface => {
	return produce(state, (trading) => {
		switch (Action.type) {
			case ActionConstants.SET_TRADE_MODE:
				trading.mode = Action.payload;
				return trading;

			case ActionConstants.SET_TRADES:
				trading.initialized = true;
				trading.trades = Action.payload;
				return trading;

			case ActionConstants.ADD_TRADE:
				trading.trades = [Action.payload, ...trading.trades];
				return trading;

			default:
				return trading;
		}
	});
};
