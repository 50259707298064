import React from 'react';
import { Route } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { EditProfile, ChangePassword, Verify } from '../pages';

const ProfileRoutes = () => {
	return (
		<>
			<Route
				path={AllRouteConstants.user.edit_profile}
				element={<EditProfile />}
			/>

			<Route
				path={AllRouteConstants.user.change_password}
				element={<ChangePassword />}
			/>

			<Route path={AllRouteConstants.user.verify} element={<Verify />} />
		</>
	);
};

export default ProfileRoutes;
