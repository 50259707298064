import axios from 'axios';
import { API_URL } from '../config/env';
import {
	ChangePasswordInput,
	TradingPlan,
	UserProfileInput,
} from '../interfaces/User';
import { BvTokens } from '../interfaces/Auth';

class UserService {
	get_me = (tokens: BvTokens) => {
		return axios.get(`${API_URL}/users/me`, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	complete_profile = (
		tokens: BvTokens,
		data: Omit<UserProfileInput, 'avatar'>
	) => {
		if (!data.phone) data.phone = '';

		return axios.patch(`${API_URL}/users`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	set_trading_plan = (tokens: BvTokens, trading_plan: TradingPlan) => {
		return axios.post(
			`${API_URL}/users/set-plan`,
			{ trading_plan },
			{
				headers: {
					Authorization: `Bearer ${tokens.access.token}`,
				},
			}
		);
	};

	request_trading_plan = (tokens: BvTokens, trading_plan: TradingPlan) => {
		return axios.post(
			`${API_URL}/users/request-plan-update`,
			{ trading_plan },
			{
				headers: {
					Authorization: `Bearer ${tokens.access.token}`,
				},
			}
		);
	};

	update_profile = (tokens: BvTokens, data: UserProfileInput) => {
		if (!data.phone) data.phone = '';

		const payload = new FormData();

		Object.keys(data).map((key) => {
			const val = data[key as keyof UserProfileInput] as any;

			if (val) payload.append(key, val);
		});

		return axios.post(`${API_URL}/users/update`, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};

	change_password = (tokens: BvTokens, data: ChangePasswordInput) => {
		return axios.patch(`${API_URL}/users/change-password`, data, {
			headers: {
				Authorization: `Bearer ${tokens.access.token}`,
			},
		});
	};
}

const userService = new UserService();
export default userService;
