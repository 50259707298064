import { Route } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { QuickTrading, TradingHistory } from '../pages';

const TradingRoutes = () => {
	return (
		<>
			<Route index element={<QuickTrading />} />
			<Route path={AllRouteConstants.user.home} element={<QuickTrading />} />
			<Route
				path={AllRouteConstants.user.trade_history}
				element={<TradingHistory />}
			/>
		</>
	);
};

export default TradingRoutes;
