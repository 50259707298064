import { Dispatch } from 'react';
import { User } from '../../interfaces/User';
import { ActionConstants } from './action-constants';
import { AuthActionTypes } from './action-types';
import { BvTokens } from '../../interfaces/Auth';

export const signIn =
	(data: { user: User; tokens: BvTokens }) =>
	(dispatch: Dispatch<AuthActionTypes>) => {
		dispatch({ type: ActionConstants.LOGIN, payload: data });
	};

export const signOut = () => (dispatch: Dispatch<AuthActionTypes>) => {
	dispatch({ type: ActionConstants.LOGOUT });
};

export const toggleSuccessAlert =
	(data?: string) => (dispatch: Dispatch<AuthActionTypes>) => {
		if (!data || typeof data !== 'string') {
			dispatch({
				type: ActionConstants.CLOSE_SUCCESS,
			});
		} else {
			dispatch({
				type: ActionConstants.OPEN_SUCCESS,
				payload: data,
			});
		}
	};

export const updateUserData =
	(data: User) => (dispatch: Dispatch<AuthActionTypes>) => {
		dispatch({ type: ActionConstants.UPDATE_USER_DATA, payload: data });
	};
