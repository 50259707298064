import React from 'react';
import { Route } from 'react-router-dom';
import { AllRouteConstants } from '../../../router/routes';
import { Profit } from '../pages';
import { useAppSelector } from '../../../hooks/useAppSelector';
import Redirect from '../../../HoC/Redirect';

const ProfitRoutes = () => {
	const { mode } = useAppSelector((state) => state.cachedReducer);

	return mode === 'real' ? (
		<>
			<Route path={AllRouteConstants.user.profit} element={<Profit />} />
		</>
	) : (
		<>
			<Route
				path={AllRouteConstants.user.profit}
				element={<Redirect path={AllRouteConstants.user.home} />}
			/>
		</>
	);
};

export default ProfitRoutes;
