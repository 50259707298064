import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import { store, persistor } from './redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import './assets/fonts/Oxanium/Oxanium-Bold.ttf';
import './assets/fonts/Poppins/Poppins-Black.ttf';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>
);
