import React from 'react';
import './styles.scss';
import { Link, useLocation } from 'react-router-dom';
import { AllRouteConstants } from '../../router/routes';
import { useAppSelector } from '../../hooks/useAppSelector';

const KrManagerNav: React.FC = () => {
	const { pathname } = useLocation();

	const isActive = (path: string) => path === pathname;

	const { mode } = useAppSelector((state) => state.cachedReducer);

	return (
		<nav className='kr-manager-nav'>
			<ul>
				<li kr-module='manager' kr-view='statistics'>
					<Link to={AllRouteConstants.user.home}>Dashboard</Link>
				</li>
				<li
					kr-module='manager'
					kr-view='statistics'
					className={
						(isActive(AllRouteConstants.user.trade_history) &&
							'kr-manager-nav-selected') ||
						''
					}
				>
					<Link to={AllRouteConstants.user.trade_history} className='no-link'>
						Trade History
					</Link>
				</li>
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='banktransferts'
						className={
							(isActive(AllRouteConstants.user.deposit_fund) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link to={AllRouteConstants.user.deposit_fund} className='no-link'>
							Deposit
						</Link>
					</li>
				)}
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='banktransferts'
						className={
							(isActive(AllRouteConstants.user.deposit_history) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link
							to={AllRouteConstants.user.deposit_history}
							className='no-link'
						>
							Deposit History
						</Link>
					</li>
				)}
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='identity'
						className={
							(isActive(AllRouteConstants.user.profit) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link to={AllRouteConstants.user.profit} className='no-link'>
							Profits
						</Link>
					</li>
				)}
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='payments'
						className={
							(isActive(AllRouteConstants.user.withdraw_fund) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link to={AllRouteConstants.user.withdraw_fund} className='no-link'>
							Withdrawal
						</Link>
					</li>
				)}
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='payments'
						className={
							(isActive(AllRouteConstants.user.withdraw_history) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link
							to={AllRouteConstants.user.withdraw_history}
							className='no-link'
						>
							Withdrawal History
						</Link>
					</li>
				)}
				{mode === 'real' && (
					<li
						kr-module='manager'
						kr-view='subscriptions'
						className={
							(isActive(AllRouteConstants.user.plan) &&
								'kr-manager-nav-selected') ||
							''
						}
					>
						<Link to={AllRouteConstants.user.plan} className='no-link'>
							Plans
						</Link>
					</li>
				)}

				<li
					kr-module='manager'
					kr-view='orders'
					className={
						(isActive(AllRouteConstants.user.edit_profile) &&
							'kr-manager-nav-selected') ||
						''
					}
				>
					<Link to={AllRouteConstants.user.edit_profile} className='no-link'>
						Profile
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default KrManagerNav;
